import LeafletMap from "./LeafletMap";
import emailSvgLogo from '../../assets/svg/email.svg';
import phoneSvgLogo from '../../assets/svg/phone.svg';
import faxSvgLogo from '../../assets/svg/fax.svg';
import locationSvgLogo from '../../assets/svg/location.svg';

export default function ContactInfo(){
    return(
        <div className="contact-info-main-container">
            <h3 className="contact-info-subheader">Skontaktuj się z nami</h3>
            <p className="contact-info-text">Nasi doświadczeni specjaliści chętnie odpowiedzą na wszystkie Twoje pytania i pomogą w wyborze odpowiedniego rozwiązania dla Twoich potrzeb.</p>
            <div className="contact-info-container">
                <div className="contact-info-email">
                    <img className="contact-info-icon" src={emailSvgLogo} alt="Email SVG logo"></img>
                    <a href="mailto:huber@huber.com.pl">huber@huber.com.pl</a>
                </div>
                <div className="contact-info-email">
                    <img className="contact-info-icon" src={emailSvgLogo} alt="Email SVG logo"></img>
                    <a href="mailto:serwis@huber.com.pl">serwis@huber.com.pl</a>
                </div>
                <div className="contact-info-phone">
                    <img className="contact-info-icon" src={phoneSvgLogo} alt="Phone SVG logo"></img>
                    tel. +48 22 572-28-60
                </div>
                <div className="contact-info-fax">
                    <img className="contact-info-icon" src={faxSvgLogo} alt="Fax SVG logo"></img>
                    fax +48 22 572-28-68

                </div>
                <div className="contact-info-fax">
                    <img className="contact-info-icon" src={faxSvgLogo} alt="Fax SVG logo"></img>
                    fax serwis +48 22 572-28-98
                </div>
                <div className="contact-info-address">
                    <img className="contact-info-icon" src={locationSvgLogo} alt="Location SVG logo"></img>
                    ul. Ryżowa 51
                    <p className="contact-info-address-city">
                        02-495 Warszawa
                    </p>
                </div>
                <div className="contact-map">
                    <LeafletMap/>
                </div>
            </div>
        </div>
    )
};
