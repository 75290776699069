import {Link} from "react-router-dom";
import './CategoryItem.css';
import React from "react";

export interface ProductProps {
    id: number;
    img: string;
    name: string;
    targetUrl: string;
    onClick?: (targetUrl: string) => void;
}

export const CategoryItem: React.FC<ProductProps> = ({ id, img, name , targetUrl, onClick }) => {
    return (
        <div className="product-item-container">
            <img className="product-item-image" src={img} alt={name} />
            <p className="product-item-name">{name}</p>
            <Link className="custom-link" to={targetUrl} onClick={() => onClick?.(targetUrl)}>
                <button className="product-item-button">Zobacz więcej</button>
            </Link>
        </div>
    );
};