import React from "react";

import {Link} from 'react-router-dom';

interface LinkProps {
    text: string;
    href: string;
    active: boolean;
}

export default function NavItem({ text, href, active  }: LinkProps) {
    const classNames = ["nav-link"];

    if (active) {
        classNames.push("active");
    }

    return (
        <Link className={classNames.join(" ")} to={href}>
            {text}
        </Link>
    );
}
