import React from 'react';
import './Footer.css'
import huberLogo from '../../src/assets/logos/HUBER_logo.jpg';
import { Link } from 'react-router-dom';
import facebookIcon from "../assets/svg/facebook2.svg";
import linkedInIcon from "../assets/svg/LINKED IN.svg";
import mdiWebIcon from "../assets/svg/mdi_web.svg";

const navigationData = [
    { label: "Strona Główna", link: "/" },
    { label: "Oferta", link: "/produkty" },
    { label: "Polityka prywatności", link: "https://www.huber.com.pl/pl/stopka-redakcyjna/polityka-prywatnosci.html" },
    { label: "Kontakt", link: "/kontakt" }
];

const socialMediaData = [
    {
        link: "https://www.facebook.com/profile.php?id=100094290905503",
        icon: facebookIcon,
        alt: "Facebook icon"
    },
    {
        link: "https://www.linkedin.com/company/huber-technology-polska",
        icon: linkedInIcon,
        alt: "LinkedIn icon"
    },
    {
        link: "https://www.huber.com.pl/pl.html",
        icon: mdiWebIcon,
        alt: "Web icon"
    }
];

const Footer: React.FC = () => {
    return (
        <div>
            <div className="footer-container">
                <div className="footer-logo-container">
                    <Link to="/">
                        <img src={huberLogo} alt="Huber logo" className="footer-logo-image"/>
                    </Link>
                </div>
                <div className="footer-nav-container">
                    {navigationData.map((item, index) => (
                        <Link key={index} to={item.link} className="footer-nav-link">
                            {item.label}
                        </Link>
                    ))}
                </div>
                <div className="footer-media-icons-container">
                    {socialMediaData.map((item, index) => (
                        <a key={index} href={item.link} className="footer-social-media-icon">
                            <img src={item.icon} alt={item.alt} />
                        </a>
                    ))}
                </div>
            </div>
            <div className="footer-bottom-divider">
                <div className="footer-copyright-container">
                    <div className="footer-copyright-text-container">
                        Copyright © 2024
                        <a className="footer-copyright-author-mail" href="mailto:Itlife.kos@gmail.com"> IT-LIFE Karol
                            Kos </a>
                        | Wszelkie prawa zastrzeżone
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Footer;

