import './App.css';
import Layout from "./Layout";
import {AppRouter} from "./router/App.router";
import CookieBanner from "./components/banners/cookiebanner/CookieBanner";
import ScrollUp from "./components/scrollup/ScrollUp";
import React from "react";

function App() {
    return (
        <>
            <Layout>
                <AppRouter/>
                <CookieBanner/>
                <ScrollUp/>
            </Layout>
        </>
    );
}

export default App;
