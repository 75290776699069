import {CategoryItem} from "../../../components/category-item/CategoryItem";

import '../Products.css';
import {technicalDoorsProducts} from "../../../Data";
import {useProductNavigation} from "../../../utils/utils";
import {Banner} from "../../../components/banners/banner/Banner";



export default function DoorsList(){
    const { handleProductClick } = useProductNavigation();

    return(
        <div>
            <Banner title={"DRZWI TECHNICZNE"}/>
            <div className="doors-main-container">
                {technicalDoorsProducts.map((product) => (
                    <CategoryItem
                        key={product.id}
                        id={product.id}
                        img={product.img}
                        name={product.name}
                        targetUrl={`/produkty/drzwi/${product.id}`}
                        onClick={() => handleProductClick(`/produkty/drzwi/${product.id}`)}
                    />
                ))}
            </div>
        </div>
    )
};
