import React from "react";
import {useParams} from "react-router-dom";
import ProductItem from "../../../components/product-item/ProductItem";
import {manholesProducts} from "../../../Data";
import Accordion from "../../../components/accordion/Accordion";
import GenericTable from "../../../components/table/GenericTable";
import SchemaImageList from "../../../components/schema-image/SchemaImagesList";
import {Banner} from "../../../components/banners/banner/Banner";

import '../../products/Products.css';

export default function ManholeDetails() {
    const {id} = useParams();
    const selectedManhole = manholesProducts.find((m) => m.id.toString() === id);

    if (!selectedManhole) {
        return <div className="not-found-product">Nie znaleziono włazu</div>;
    }

    const shouldRenderTable = selectedManhole.specifications && selectedManhole.specifications.length > 1;

    const additionalInfo = selectedManhole.additionalSpecifications && selectedManhole.additionalSpecifications.length > 1

    return (
        <div>
            <Banner title={"WŁAZY"}/>
            <ProductItem item={selectedManhole} />
            <div className="container center-container">
                <div className="accordions-section">
                    {shouldRenderTable && (
                        <Accordion
                            title="Tabela wymiarów"
                            content={<GenericTable columns={selectedManhole.columns as any[]} data={selectedManhole.specifications}/>}
                        />
                    )}
                </div>
                {additionalInfo && (
                    <div className="accordions-section">
                        <Accordion
                            title="Tabela wymiarów (Dodatkowa)"
                            content={<GenericTable columns={selectedManhole.columns as any[]} data={selectedManhole.additionalSpecifications}/>}
                        />
                    </div>
                )

                }
                <div className="accordions-section">
                    <Accordion
                        title="Rysunek techniczny"
                        content={
                            <SchemaImageList images={selectedManhole.schemas} />
                        }
                    />
                </div>
            </div>
        </div>
    );
};

