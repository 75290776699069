import {Link} from "react-router-dom";
import equipmentLadders from '../../../assets/banners/equipmentLadders.jpg';
import equipmentManholes from '../../../assets/banners/equipmentManholes.jpg';

import '../Products.css';
import {Banner} from "../../../components/banners/banner/Banner";
import React from "react";

export default function EquipmentCategories() {
    return (
       <div>
           <Banner title={"WYPOSAŻENIE"}/>
           <div className="center-container">
               <div className="equipment-categories-container">
                   <p className="equipment-categories-description">Wybierz kategorie wyposażenia</p>
               </div>
               <div className="equipment-main-container">
                   <Link to="/produkty/wyposazenie/wlazy">
                       <img className="equipment-banner-image" src={equipmentManholes} alt="EquipmentCategories manholes"></img>
                   </Link>
                   <Link to="/produkty/wyposazenie/drabiny">
                       <img className="equipment-banner-image" src={equipmentLadders} alt="EquipmentCategories ladders"></img>
                   </Link>
               </div>
           </div>
       </div>
    );
}
