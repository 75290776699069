import './Home.css';
import huberManufactureGermanyImage from "../../assets/images/huber-manufacture.jpg";
import huberPolandQuartersImage from "../../assets/images/huber-polska.jpg";
import background1 from "../../assets/images/background4.jpg";
import background2 from "../../assets/images/background1.jpg";
import background3 from "../../assets/images/background3.jpg";
import background4 from "../../assets/images/background2.jpg";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowRight} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";
import Carousel from "react-material-ui-carousel";
import React from "react";

const items = [
    {
        imgPath: background1,
        alt: 'Image 1',
        header: 'Nasze rozwiązania bezpieczeństwa',
        text: 'Posiadamy szeroką ofertę skierowaną do firm oraz do indywidualnych klientów.',
        path: '/produkty'
    },
    {
        imgPath: background2,
        alt: 'Image 2',
        header: 'Zapewniamy klientom najwyższą jakość',
        text: 'Od 25 lat obecni na rynku! Ponad 700 zrealizowanych projektów, 1500 sprzedanych maszyn. To pokazuje, że mamy niezrównaną wiedzę i doświadczenie w naszej dziedzinie.',
        path: '/produkty'
    },
    {
        imgPath: background3,
        alt: 'Image 3',
        header: 'Nasze rozwiązania',
        text: 'Jesteśmy ekspertami w dziedzinie uzdatniania wody i oczyszczania ścieków. Jeśli masz problem, pozwól nam zaproponować rozwiązanie dostosowane do Twoich potrzeb.',
        path: '/produkty'
    },
    {
        imgPath: background4,
        alt: 'Image 4',
        header: 'Urządzenia spersonalizowane',
        text: 'W naszej ofercie posiadamy pełen zakres maszyn do oczyszczania ścieków, wykorzystania wody użytkowej, uzdatniania wody pitnej, obróbki piasku i przeróbki osadu.',
        path: '/produkty'
    },
];

export default function Home() {
    return (
        <div className="home-main-container">
            <Carousel animation="slide" navButtonsAlwaysVisible interval={5000}>
                {items.map((item, index) => (
                    <div key={index}>
                        <img className="carousel-image" src={item.imgPath} alt={item.alt}/>
                        <p className="carousel-header">
                            {item.header}
                        </p>
                        <p className="carousel-text">
                            {item.text}
                        </p>
                        <div className="carousel-buttons-container">
                            <div className="carousel-buttons-subcontainer">
                                <a className="custom-link" href={item.path}>
                                    <button className="carousel-view-offer-button">
                                        Zobacz ofertę
                                        <FontAwesomeIcon icon={faArrowRight}/>
                                    </button>
                                </a>
                                <Link className="custom-link" to="/kontakt">
                                    <button className="carousel-contact-us-button">
                                        Skontaktuj się z nami
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>
                ))}
            </Carousel>
            <div className="aboutUs-container">
                <div className="aboutUs-first">
                    <div className="aboutUs-text-first">
                        <h2 className="aboutUs-oNas-heading">O nas</h2>
                        <p className="aboutUs-heading">Technologia w ochronie środowiska</p>
                        <p className="aboutUs-description">Firma Huber Technology Sp. z o.o jest jedynym w Polsce
                            przedstawicielem znanej na całym świecie firmy Huber SE, działającej w dziedzinie oczyszczania
                            ścieków i przeróbki osadów ściekowych.</p>
                        <p className="aboutUs-description"> Posiadamy kompletną ofertę urządzeń obejmujących cały proces
                            technologiczny przetwarzania ścieków, a nasze produkty są zaprojektowane w sposób zapewniający
                            klientom i środowisku optymalne korzyści</p>
                        <p className="aboutUs-description">Wszystkie urządzenia w naszej ofercie wykonane są z
                            wysokogogatunkowej stali odpornej na korozję.</p>
                    </div>
                    <div className="aboutUs-manufacture-image-container">
                        <img src={huberManufactureGermanyImage} className="aboutUs-manufacture-image" alt="Huber manufacture"></img>
                        <p className="manufacture-description">Hala produkcyjna o powierzchni 30 000 m² oraz biura w
                            Niemczech</p>
                    </div>
                </div>
                <div className="aboutUs-second">
                    <div className="aboutUs-headquarter-image-container">
                        <img src={huberPolandQuartersImage} className="aboutUs-headquarter-image" alt="Huber Poland headquarter"></img>
                        <p className="headquarter-description">Siedziba firmy Huber Technology sp. z o.o.</p>
                    </div>
                    <div className="aboutUs-text-second" style={{textAlign: 'center'}}>
                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                            <div style={{alignItems: 'center', justifyContent: 'center'}}>
                                <p className="aboutUs-description" style={{fontWeight: '700'}}>W naszej ofercie posiadamy
                                    urządzenia do:</p>
                                <ul className="aboutUs-ul" style={{listStylePosition: 'inside', textAlign: 'left'}}>
                                    <li>mechanicznego oczyszczania ścieków</li>
                                    <li>obróbki skratek</li>
                                    <li>separacji i obróbki piasku</li>
                                    <li>przeróbki osadu</li>
                                    <li>membranowego oczyszczania ścieków</li>
                                </ul>
                                <p className="aboutUs-description" style={{fontWeight: '700'}}>Oferujemy rozwiązania
                                    dla:</p>
                                <ul className="aboutUs-ul" style={{listStylePosition: 'inside', textAlign: 'left'}}>
                                    <li>suszenia osadów ściekowych</li>
                                    <li>lokalnej utylizacji osadów ściekowych</li>
                                    <li>oczyszczania ścieków dowożonych (stacje zlewne)</li>
                                    <li>zbiorników wody pitnej</li>
                                    <li>scentralizowanego oczyszczania ścieków lokalnych i małych oczyszczalni ścieków oraz
                                        odzysku wody
                                    </li>
                                    <li>odzysku ciepła ze ścieków</li>
                                    <li>ścieków "szarych"</li>
                                    <li>oczyszczania piasku z kanalizacji i mycia ulic</li>
                                    <li>przemysłu</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )};