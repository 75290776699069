import './Banner.css';
import React, {} from "react";

import banner from '../../../assets/banners/banner.jpg';

interface BannerProps {

    title: string;
}

export const Banner: React.FC<BannerProps> = ({  title}) => {
    return(
        <div className="banner-container">
            <img className="banner-image" src={banner} alt={"Banner"}></img>
            <p className="banner-title">{title}</p>
        </div>

    )
}
