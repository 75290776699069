import React from "react";
import {useParams} from "react-router-dom";
import {equipmentManholesProducts} from "../../../../Data";
import ProductItem from "../../../../components/product-item/ProductItem";
import Accordion from "../../../../components/accordion/Accordion";
import GenericTable from "../../../../components/table/GenericTable";
import SchemaImageList from "../../../../components/schema-image/SchemaImagesList";
import {Banner} from "../../../../components/banners/banner/Banner";

import '../../Products.css';

export default function EquipmentManholeDetails() {
    const {id} = useParams();
    const selectedManhole = equipmentManholesProducts.find((m) => m.id.toString() === id);

    if (!selectedManhole) {
        return <div className="not-found-product">Nie znaleziono wyposażenia do włazów</div>;
    }

    const shouldRenderTable = selectedManhole.specifications && selectedManhole.specifications.length > 1;
    const shouldRenderTechnicalDrawing = selectedManhole.schemas && selectedManhole.schemas.length > 0;


    return (
        <div>
            <Banner title={"WYPOSAŻENIE"}/>
            <ProductItem item={selectedManhole}/>
            <div className="container center-container">
                <div className="accordions-section">
                    {shouldRenderTable && (
                        <Accordion
                            title="Tabela wymiarów"
                            content={<GenericTable columns={selectedManhole.columns as any[]}
                                                   data={selectedManhole.specifications}/>}
                        />
                    )}
                </div>
                <div className="accordions-section">
                    {shouldRenderTechnicalDrawing && (
                        <Accordion
                            title="Rysunek techniczny"
                            content={
                                <SchemaImageList images={selectedManhole.schemas}/>
                            }
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

