import React from 'react';
import './GenericTable.css';

interface Column {
    key: string;
    title: string;
}

interface Props {
    columns: Column[];
    data: Record<string, any>[];
}

const GenericTable: React.FC<Props> = ({ columns, data }) => {
    return (
        <div>
            <table id="table">
                <thead>
                <tr>
                    {columns.map(column => (
                        <th key={column.key}>{column.title}</th>
                    ))}
                </tr>
                </thead>
                <tbody>
                {data.map((row, index) => (
                    <tr key={index}>
                        {columns.map(column => (
                            <td key={column.key}>{row[column.key]}</td>
                        ))}
                    </tr>
                ))}
                </tbody>
            </table>
            <p className="table-description">Wszystkie wymiary podano w mm</p>
        </div>

    );
};

export default GenericTable;
