import sd1StandardImage from "./assets/products/manholes/SD1/SD1 standard.jpg";
import sd1KeyImage from "./assets/products/manholes/SD1/SD1 key.jpg";
import sd1Image from "./assets/products/manholes/SD1/SD1.jpg";
import sd1SchemaImage1 from "./assets/products/manholes/SD1/SD1_schemat a x b.jpg";
import sd1SchemaImage2 from "./assets/products/manholes/SD1/SD1_schemat c x d, e x f.jpg";
import sd2StandardImage from "./assets/products/manholes/SD2/SD2 standard.jpg";
import sd2OpenImage from "./assets/products/manholes/SD2/SD2.jpg";
import sd2VentImage from "./assets/products/manholes/SD2/SD2 vent.jpg";
import sd2SchemaImage1 from "./assets/products/manholes/SD2/SD2_schemat b.jpg";
import sd2SchemaImage2 from "./assets/products/manholes/SD2/SD2_schemat c.jpg";
import sd1_PStandardImage from './assets/products/manholes/SD1_P/SD1_P standard.jpg';
import sd1_PVentImage from './assets/products/manholes/SD1_P/SD1_P vent.jpg';
import sd1_POpenImage from './assets/products/manholes/SD1_P/SD1_P.jpg';
import sd1_PSchemaImage1 from './assets/products/manholes/SD1_P/SD1_P schemat a x b.jpg';
import sd1_PSchemaImage2 from './assets/products/manholes/SD1_P/SD1_P schemat c x d, e x f.jpg';
import sd3RC3StandardImage from './assets/products/manholes/SD3_RC3/SD3_RC3_standard.jpg';
import sd3RC3VentImage from './assets/products/manholes/SD3_RC3/SD3_RC3_vent.jpg';
import sd3RC3OpenImage from './assets/products/manholes/SD3_RC3/SD3_RC3.jpg';
import sd3RC3SchemaImage1 from './assets/products/manholes/SD3_RC3/SD3_RC3_schemat a x b.jpg';
import sd3RC3SchemaImage2 from './assets/products/manholes/SD3_RC3/SD3_RC3_schemat c x d, e x f.jpg';
import sd4RC3Image from './assets/products/manholes/SD4_RC3/SD4_RC3.jpg';
import sd4RC3SchemaImage1 from './assets/products/manholes/SD4_RC3/SD4_RC3_schemat.jpg';
import sd4RC3SchemaImage2 from './assets/products/manholes/SD4_RC3/SD4_RC3_schemat b, c.jpg';
import sd50Image from './assets/products/manholes/SD50/SD50.jpg';
import sd50OpenImage from './assets/products/manholes/SD50/SD50_open.jpg';
import sd50SchemaImage1 from './assets/products/manholes/SD50/SD50_schemat a x b.jpg';
import sd50SchemaImage2 from './assets/products/manholes/SD50/SD50_schemat c x d, e x f.jpg';
import sd5WaterproofImage from './assets/products/manholes/SD5/SD5.jpg';
import sd5WaterproofOpenImage from './assets/products/manholes/SD5/SD5_open.jpg';
import sd5SchemaImage1 from './assets/products/manholes/SD5/SD5_schemat a x b.jpg';
import sd5SchemaImage2 from './assets/products/manholes/SD5/SD5_schemat c x d, e x f.jpg';
import sd6WaterproofImage from './assets/products/manholes/SD6/SD6.jpg';
import sd6WaterproofOpenImage from './assets/products/manholes/SD6/SD6_open.jpg';
import sd6SchemaImage1 from './assets/products/manholes/SD6/schemat a.jpg';
import sd6SchemaImage2 from './assets/products/manholes/SD6/schemat b, c.jpg';
import sd7HighResistanceImage from './assets/products/manholes/SD7/SD7.jpg';
import sd7HighResistanceKeyImage from './assets/products/manholes/SD7/SD7_key.jpg';
import sd7HighResistanceOpenImage from './assets/products/manholes/SD7/SD7_OPEN.jpg';
import sd7Schema1Image from './assets/products/manholes/SD7/SD7_schemat axb.jpg';
import sd7Schema2Image from './assets/products/manholes/SD7/SD7_schemat c x d, e x f.jpg';
import sd7_R_HighResistanceImage from './assets/products/manholes/SD7R/SD7.R.jpg';
import sd7_R_SchemaImage1 from './assets/products/manholes/SD7R/SD7.R_schemat a.jpg';
import sd7_R_SchemaImage2 from './assets/products/manholes/SD7R/SD7.R_schemat b, c.jpg';
import sd7_RC4HighResistanceImage from './assets/products/manholes/SD7_RC4/SD7_RC4.jpg';
import sd7_RC4Schema1Image from './assets/products/manholes/SD7_RC4/SD7-RD4_schemat a x b.jpg';
import sd7_RC4Schema2Image from './assets/products/manholes/SD7_RC4/SD7-RD4_schemat c x d, e x f.jpg';
import sd11LightweightImage from './assets/products/manholes/SD11/SD11.jpg';
import sd11Schema_a_Image from './assets/products/manholes/SD11/SD11_schemat a.jpg';
import sd11Schema_b_Image from './assets/products/manholes/SD11/SD11_schemat b.jpg';
import sd11Schema_c_Image from './assets/products/manholes/SD11/SD11_schemat c.jpg';
import sd11Schema_d_Image from './assets/products/manholes/SD11/SD11_schemat d.jpg';
import sd11SchemaImage1 from './assets/products/manholes/SD11/SD11_schemat 1.jpg';
import sd11SchemaImage2 from './assets/products/manholes/SD11/SD11_schemat 2.jpg';
import sd12SlidingImage from './assets/products/manholes/SD12/SD12.jpg';
import sd12SlidingOpenImage from './assets/products/manholes/SD12/SD12_.jpg';
import sd12SchemaImage from './assets/products/manholes/SD12/SD12_schemat.jpg';

import TTI1_1StandardAImage from './assets/products/technical-doors/TT1.1/TT1.1_A.jpg';
import TTI1_1StandardBImage from './assets/products/technical-doors/TT1.1/TT1.1_B.jpg';
import TTI1_1SchemaImage from './assets/products/technical-doors/TT1.1/TT1.1.jpg';
import TTI1_1_WStandardImage from './assets/products/technical-doors/TT1.1W/TT1.1 W_a.jpg';
import TTI1_1_WOpenImage from './assets/products/technical-doors/TT1.1W/TT1.1 W_b.jpg';
import TTI1_1_WSchemaImage from './assets/products/technical-doors/TT1.1W/TT1.1 W.jpg';
import TTI1_2StandardImage1 from './assets/products/technical-doors/TT1.2/TT1.2.jpg';
import TTI1_2StandardImage2 from './assets/products/technical-doors/TT1.2/TT1.2_a.jpg';
import TTI1_2SchemaImage from './assets/products/technical-doors/TT1.2/TT1.2schema.jpg';
import TTI2_1_RC3StandardImage1 from './assets/products/technical-doors/TT2.1_RC3/TT2.1_RC3.jpg';
import TTI2_1_RC3StandardImage2 from './assets/products/technical-doors/TT2.1_RC3/TT2.1_RC3_A.jpg';
import TTI2_1_RC3SchemaImage from './assets/products/technical-doors/TT2.1_RC3/TT2.1_RC3_schemat.jpg';
import TTI2_2_RC3StandardImage1 from './assets/products/technical-doors/TT2.2_RC3/TT2.2_RC3_a.jpg';
import TTI2_2_RC3StandardImage2 from './assets/products/technical-doors/TT2.2_RC3/TT2.2_RC3_b.jpg';
import TTI2_2_RC3SchemaImage from './assets/products/technical-doors/TT2.2_RC3/TT2.2_RC3schemat.jpg';
import TTI2_1_RC4StandardImage1 from './assets/products/technical-doors/TT2.1_RC4/TT2.1_RC4.jpg';
import TTI2_1_RC4StandardImage2 from './assets/products/technical-doors/TT2.1_RC4/TT2.1_RC4a.jpg';
import TTI2_1_RC4SchemaImage from './assets/products/technical-doors/TT2.1_RC4/TT2.1_RC4_schemat.jpg';
import TTI2_2_RC4StandardImage from './assets/products/technical-doors/TT2.2_RC4/TT2.2_RC4a.jpg';
import TTI2_2_RC4SchemaImage from './assets/products/technical-doors/TT2.2_RC4/TT2.2_RC4.jpg';
import TT5StandardImage1 from './assets/products/technical-doors/TT5/TT5.jpg';
import TT5StandardImage2 from './assets/products/technical-doors/TT5/TT5a.jpg';
import TT5SchemaImage from './assets/products/technical-doors/TT5/TT5_schemat.jpg';
import TT6StandardImage1 from './assets/products/technical-doors/TT6/TT6.jpg';
import TT6StandardImage2 from './assets/products/technical-doors/TT6/TT6a.jpg';
import TT6SchemaImage from './assets/products/technical-doors/TT6/TT6_schemat.jpg';
import TT7StandardImage1 from './assets/products/technical-doors/TT7/TT7.jpg';
import TT7StandardImage2 from './assets/products/technical-doors/TT7/TT7a.jpg';
import TT7SchemaImage1 from './assets/products/technical-doors/TT7/TT7_schemat.jpg';
import TT7SchemaImage2 from './assets/products/technical-doors/TT7/TT7_schemat_a.jpg';
import TT7ZStandardImage from './assets/products/technical-doors/TT7.Z/TT7_Z.jpg';
import TT7ZSchemaImage1 from './assets/products/technical-doors/TT7.Z/TT7_Zschemat.jpg';
import TT7ZSchemaImage2 from './assets/products/technical-doors/TT7.Z/TT7_Zschemat.jpg';
import TT7RStandardImage from './assets/products/technical-doors/TT7_R/TT7_R-2.jpg';
import TT7RSchemaImage from './assets/products/technical-doors/TT7_R/TT7R.jpg';
import TT30StandardImage from './assets/products/technical-doors/TT7_30/TT7_30.jpg';
import TT30SchemaImage from './assets/products/technical-doors/TT7_30/TT7_30_schemat.jpg';

import EH_K_StandardImage1 from './assets/products/equipment/ladders/EH_K/EH K.jpg';
import EH_K_StandardImage2 from './assets/products/equipment/ladders/EH_K/EH K1.jpg';
import EH_K_SchemaImage from './assets/products/equipment/ladders/EH_K/EH K_schemat.jpg';
import EH_KV_StandardImage1 from './assets/products/equipment/ladders/EH_KV/EH KV.jpg';
import EH_KV_StandardImage2 from './assets/products/equipment/ladders/EH_KV/EH KV1.jpg';
import EH_KV_SchemaImage from './assets/products/equipment/ladders/EH_KV/EH KV_schemat.jpg';
import EH_KS_StandardImage from './assets/products/equipment/ladders/EH_S/EH S.jpg';
import EH_KS_SchemaImage from './assets/products/equipment/ladders/EH_S/EH S_schemat.jpg';
import EH_VSD_StandardImage1 from './assets/products/equipment/ladders/EH_VSD/EH VSD.jpg';
import EH_VSD_StandardImage2 from './assets/products/equipment/ladders/EH_VSD/EH VSD1.jpg';
import EH_VSD_SchemaImage from './assets/products/equipment/ladders/EH_VSD/EH VSD_schemat.jpg';
import EH_VS_StandardImage from './assets/products/equipment/ladders/EH_VS/EH VS.jpg';
import EH_VS_SchemaImage from './assets/products/equipment/ladders/EH_VS/EH VS_schemat.jpg';
import SiS5_HB_StandardImage1 from './assets/products/equipment/ladders/SiS5_HB/Sis5HB.jpg';
import SiS5_HB_StandardImage2 from './assets/products/equipment/ladders/SiS5_HB/Sis5HB1.jpg';
import SiS5_HB_SchemaImage from './assets/products/equipment/ladders/SiS5_HB/Sis5HB_schemat.jpg';
import SiS2_StandardImage1 from './assets/products/equipment/ladders/SiS2/SiS2.jpg';
import SiS2_StandardImage2 from './assets/products/equipment/ladders/SiS2/SiS2_.jpg';
import SiS2_StandardImage3 from './assets/products/equipment/ladders/SiS2/SiS2_a.jpg';
import SiS2_SchemaImage from './assets/products/equipment/ladders/SiS2/SiS2_schemat.jpg';
import SiS3_StandardImage1 from './assets/products/equipment/ladders/SiS3/SiS3.jpg';
import SiS3_StandardImage2 from './assets/products/equipment/ladders/SiS3/SiS3_.jpg';
import SiS3_StandardImage3 from './assets/products/equipment/ladders/SiS3/SiS3_1.jpg';
import SiS3_SchemaImage from './assets/products/equipment/ladders/SiS3/SiS3_schemat.jpg';
import S5C_StandardImage1 from './assets/products/equipment/ladders/S5C/S5c.jpg';
import S5C_StandardImage2 from './assets/products/equipment/ladders/S5C/S5c1.jpg';
import S5C_StandardImage3 from './assets/products/equipment/ladders/S5C/S5c2.jpg';
import EH_FFS_StandardImage1 from './assets/products/equipment/ladders/EH_FSS/EH FSS_1.jpg';
import EH_FFS_StandardImage2 from './assets/products/equipment/ladders/EH_FSS/EH FSS_2.jpg';
import EH_FFS_SchemaImage from './assets/products/equipment/ladders/EH_FSS/EH FSS.jpg';

import Krata_ASG_StandardImage1 from './assets/products/equipment/manholes/KrataASG/ASG1.jpg';
import Krata_ASG_StandardImage2 from './assets/products/equipment/manholes/KrataASG/ASG.jpg';
import Krata_ASG_SchemaImage1 from './assets/products/equipment/manholes/KrataASG/krata ASG.jpg';
import Krata_ASG_SchemaImage2 from './assets/products/equipment/manholes/KrataASG/krata ASG_SD7.jpg';
import Krata_ASG_P_StandardImage1 from './assets/products/equipment/manholes/KrataASG_P/ASG.P.jpg';
import Krata_ASG_P_StandardImage2 from './assets/products/equipment/manholes/KrataASG_P/ASG.P1.jpg';
import Krata_ASG_P_SchemaImage1 from './assets/products/equipment/manholes/KrataASG_P/ASG.P_schemat SD1.jpg';
import Krata_ASG_P_SchemaImage2 from './assets/products/equipment/manholes/KrataASG_P/ASG.P_schemat SD7.jpg';
import Zbiornik_CT_StandardImage from './assets/products/equipment/manholes/ZbiornikCT/CT.jpg';
import Zbiornik_CT_SchemaImage from './assets/products/equipment/manholes/ZbiornikCT/CT_schemta.jpg';

import SiS1_StandardImage1 from './assets/products/ladders/SiS1/SiS1.jpg';
import SiS1_StandardImage2 from './assets/products/ladders/SiS1/SiS1_.jpg';
import SiS1_SchemaImage1 from './assets/products/ladders/SiS1/SiS1_A.jpg';
import SiS1_SchemaImage2 from './assets/products/ladders/SiS1/SiS1_B.jpg';
import SiS1_SchemaImage3 from './assets/products/ladders/SiS1/SiS1_C.jpg';
import manholeImage from "./assets/category/manhole.jpg";
import ladderImage from "./assets/category/ladder.jpg";
import technicalDoorImage from "./assets/category/technical-door.jpg";
import equipmentLadderImage from "./assets/category/equipmentLadder.jpg";




export const categoryData = [
    {
        image: manholeImage,
        title: "Włazy",
        url: "/produkty/wlazy",
    },
    {
        image: ladderImage,
        title: "Drabiny",
        url: "/produkty/drabiny",
    },
    {
        image: technicalDoorImage,
        title: "Drzwi techniczne",
        url: "/produkty/drzwi",
    },
    {
        image: equipmentLadderImage,
        title: "Wyposażenie",
        url: "/produkty/wyposazenie",
    },
];



const doorsColumnsDefault = [
    { key: 'orderNumber', title: 'Nr zamówienia' },
    { key: 'internalSize', title: 'Szerokość otworu B / wysokość H' },
    { key: 'overallSize', title: 'Wymiary zewnętrzne szerokość/wysokość' },
];

const doorsColumnsModernization = [
    { key: 'orderNumber', title: 'Nr zamówienia' },
    { key: 'internalSize', title: 'Szerokość otworu B / wysokość H' },
    { key: 'overallSize', title: 'Wymiary zewnętrzne szerokość/wysokość' },
    { key: 'internalSizeModernize', title: 'Wymiary zewnętrzne szerokość/wysokość' },
    { key: 'overallSizeModernize', title: 'Wymiary zewnętrzne szerokość/wysokość' },
];

const doorsColumnsTank = [
    { key: 'orderNumber', title: 'Nr zamówienia' },
    { key: 'internalSize', title: 'Średnica Ø' },
    { key: 'overallSize', title: 'Średnica zewnętrzna Ø_1' },
]

const rectAngularManholeColumns = [
    { key: 'model', title: 'Model' },
    { key: 'size', title: 'Wielkość a x b' },
    { key: 'orderNumber', title: 'Nr zamówienia' },
    { key: 'internalSize', title: 'Rozmiar wewnętrzny c x d' },
    { key: 'overallSize', title: 'Rozmiar całkowity e x f' },
]

const roundedManholeColumns = [
    { key: 'model', title: 'Model' },
    { key: 'size', title: 'Wielkość ø a' },
    { key: 'orderNumber', title: 'Nr zamówienia' },
    { key: 'internalSize', title: 'Rozmiar wewnętrzny ø b' },
    { key: 'overallSize', title: 'Rozmiar całkowity ø c' },
]

const equipmentLadders1 = [
    { key: 'type', title: 'Rodzaj' },
    { key: 'orderNumber', title: 'Nr zamówienia' },
]

const equipmentLadders2 = [
    { key: 'height', title: 'Wysokość drabiny L (m)' },
    { key: 'steps', title: 'Liczba szczebli' },
    { key: 'orderNumberWidth400', title: 'Nr zamówienia (dot. szerokości drabiny: 400 mm)' },
    { key: 'orderNumberWidth500', title: 'Nr zamówienia (dot. szerokości drabiny: 500 mm)' },
]

const equipmentLadders3 = [
    { key: 'depthFrom', title: 'Głębokość studzienki od (m)' },
    { key: 'depthTo', title: 'Głębokość studzienki  do (m)' },
    { key: 'ladderLength', title: 'Długość drabiny (m)' },
    { key: 'steps', title: 'Liczba szczebli)' },
    { key: 'orderNumber', title: 'Nr zamówienia' },
]

const equipmentManholesDefault = [
    { key: 'model', title: 'Model' },
    { key: 'size', title: 'Wielkość a x b)' },
    { key: 'orderNumber', title: 'Nr zamówienia' },
]

const equipmentManholesTank = [
    { key: 'model', title: 'Model' },
    { key: 'size', title: 'Wielkość a x b)' },
    { key: 'orderNumber', title: 'Nr zamówienia' },
    { key: 'internalDimensions', title: 'Wymiary wewnętrzne c x d' },
    { key: 'externalDimensions', title: 'Wymiary zewnętrzne e x f' },
]

const laddersColumn = [
    { key: 'length', title: 'Długość (m)' },
    { key: 'steps', title: 'Liczba szczebli' },
    { key: 'width', title: 'Szerokość' },
    { key: 'verticalMount', title: 'Montaż pionowy (A)' },
    { key: 'angledWallMount', title: 'Montaż do ściany pod kątem (B)' },
    { key: 'floorMount', title: 'Montaż do podłogi (C)' },
]


export const manholesProducts = [
    {
        id: 1,
        name: 'Właz SD1 typ prostokątny',
        description: 'Właz odporny na warunki atmosferyczne, o kształcie prostokąta, w całości wykonany ze stali nierdzewnej 1.4307 (AISI 304 L), zgodnie z normą DIN 1239:2018-4. Odpowiedni do stosowania w strefie Ex 1.\n' +
            'Pokrywa wykonana jest z blachy nierdzewnej o grubości 2 mm, z centralnie uniesionym profilem, wewnętrznym wzmocnieniem krzyżowym zapewniającym stabilność, zamkiem, amortyzatorem sprężynowym ze stali nierdzewnej, zintegrowanym ogranicznikiem; solidne, ukryte zawiasy.\n' +
            'Właz i rama spawane łukowo, wytrawiane w kąpieli kwaśnej i pasywowane. \n' +
            'Klucz obsługowy w zestawie.',
        columns: rectAngularManholeColumns,
        img : sd1StandardImage,
        otherImgs: [sd1StandardImage, sd1KeyImage, sd1Image],
        schemas: [sd1SchemaImage1, sd1SchemaImage2],
        specifications: [
            { model: 'Standard', size: '600 x 600', orderNumber: '166/G (1)', internalSize: '460 x 545', overallSize: '700 x 700' },
            { model: 'Standard', size: '800 x 800', orderNumber: '188/G', internalSize: '660 x 745', overallSize: '900 x 900' },
            { model: 'Standard', size: '1000 x 1000', orderNumber: '111/G', internalSize: '900 x 855', overallSize: '1100 x 1100' },
            { model: 'Z wentylacją DN 150', size: '600 x 600', orderNumber: '166/GD (1)', internalSize: '460 x 545', overallSize: '700 x 700' },
            { model: 'Z wentylacją DN 150', size: '800 x 800', orderNumber: '188/GD', internalSize: '660 x 745', overallSize: '900 x 900' },
            { model: 'Z wentylacją DN 150', size: '1000 x 1000', orderNumber: '111/GD', internalSize: '900 x 855', overallSize: '1100 x 1100' },
            { model: 'Z izolacją pokrywy, pozbawiona FCKW', size: '600 x 600', orderNumber: '166/I (1)', internalSize: '460 x 545', overallSize: '700 x 700' },
            { model: 'Z izolacją pokrywy, pozbawiona FCKW', size: '800 x 800', orderNumber: '188/I', internalSize: '660 x 745', overallSize: '900 x 900' },
            { model: 'Z izolacją pokrywy, pozbawiona FCKW', size: '1000 x 1000', orderNumber: '111/I', internalSize: '900 x 855', overallSize: '1100 x 1100' },
            { model: 'Z wentylacją DN 150 i izolacją pokrywy, pozbawiona FCKW', size: '600 x 600', orderNumber: '166/ID (1)', internalSize: '460 x 545', overallSize: '700 x 700' },
            { model: 'Z wentylacją DN 150 i izolacją pokrywy, pozbawiona FCKW', size: '800 x 800', orderNumber: '188/ID', internalSize: '660 x 745', overallSize: '900 x 900' },
            { model: 'Z wentylacją DN 150 i izolacją pokrywy, pozbawiona FCKW', size: '1000 x 1000', orderNumber: '111/ID', internalSize: '900 x 855', overallSize: '1100 x 1100' },
        ],
    },
    {
        id: 2,
        name: 'Właz SD2 typ okrągły',
        description: 'Właz odporny na warunki atmosferyczne, o okrągłym kształcie, wykonany ze stali nierdzewnej 1.4307 (AISI 304 L), zgodnie z normą DIN 1239:2018-4. Odpowiedni do stosowania w strefie Ex 1.\n' +
            'Pokrywa wykonana z blachy nierdzewnej o grubości 2 mm, z centralnie podwyższonym profilem, wewnętrznym krzyżowym usztywnieniem zapewniającym stabilność, zamkiem, amortyzatorem sprężynowym ze stali nierdzewnej, zintegrowanym ogranicznikiem; solidne, ukryte zawiasy.\n' +
            'Właz i rama spawane łukowo, wytrawiane w kąpieli kwaśnej i pasywowane. Klucz obsługowy w zestawie.',
        img: sd2StandardImage,
        columns: roundedManholeColumns,
        otherImgs: [sd2StandardImage, sd2VentImage, sd2OpenImage],
        schemas: [sd2SchemaImage1, sd2SchemaImage2],
        specifications: [
            { model: 'Standard', size: '600', orderNumber: '106/GR (1)', internalSize: '495', overallSize: '675' },
            { model: 'Standard', size: '800', orderNumber: '108/GR', internalSize: '695', overallSize: '875' },
            { model: 'Standard', size: '1000', orderNumber: '101/GR', internalSize: '895', overallSize: '1075' },
            { model: 'Z wentylacją DN 150', size: '600', orderNumber: '106/GDR (1)', internalSize: '495', overallSize: '675' },
            { model: 'Z wentylacją DN 150', size: '800', orderNumber: '108/GDR', internalSize: '695', overallSize: '875' },
            { model: 'Z wentylacją DN 150', size: '1000', orderNumber: '101/GDR', internalSize: '895', overallSize: '1075' },
            { model: 'Z izolacją pokrywy, pozbawiona FCKW', size: '600', orderNumber: '106/IR (1)', internalSize: '495', overallSize: '675' },
            { model: 'Z izolacją pokrywy, pozbawiona FCKW', size: '800', orderNumber: '108/IR', internalSize: '695', overallSize: '875' },
            { model: 'Z izolacją pokrywy, pozbawiona FCKW', size: '1000', orderNumber: '101/IR', internalSize: '895', overallSize: '1075' },
            { model: 'Z wentylacją DN 150 i izolacją pokrywy, pozbawiona FCKW', size: '600', orderNumber: '106/IDR (1)', internalSize: '495', overallSize: '675' },
            { model: 'Z wentylacją DN 150 i izolacją pokrywy, pozbawiona FCKW', size: '800', orderNumber: '108/IDR', internalSize: '695', overallSize: '875' },
            { model: 'Z wentylacją DN 150 i izolacją pokrywy, pozbawiona FCKW', size: '1000', orderNumber: '101/IDR', internalSize: '895', overallSize: '1075' },
        ],
    },
    {
        id: 3,
        name: 'Właz SD1.P model premium, typ prostokątny',
        description: 'Właz - model premium - odporny na warunki atmosferyczne, w kształcie prostokąta, w całości wykonana ze stali nierdzewnej 1.4307 (AISI 304 L), zgodnie z normą DIN 1239:2018-4. Odpowiedni do stosowania w strefie Ex 1.\n' +
            'Pokrywa wykonana z blachy nierdzewnej, z centralnie podniesionym profilem, wewnętrznym krzyżowym wzmocnieniem zapewniającym stabilność, zamkiem otwieranym specjalnym kluczem, dwoma amortyzatorami ze stali nierdzewnej, ze zintegrowanym ogranicznikiem, ze stabilnymi ukrytymi zawiasami chroniącymi przed wandalizmem, z gumową uszczelką odporną na warunki atmosferyczne.\n' +
            'Izolacja pokrywy wykonana z twardej pianki poliuretanowej, bez freonu, z dolną płytą ochronną ze stali nierdzewnej.\n' +
            'Właz i rama spawane łukowo, wytrawiane w kąpieli kwaśnej i pasywowane. W zestawie materiał mocujący, uszczelka i klucz obsługowy.',
        columns: rectAngularManholeColumns,
        img: sd1_PStandardImage,
        otherImgs: [sd1_PStandardImage, sd1_PVentImage, sd1_POpenImage],
        schemas: [sd1_PSchemaImage1, sd1_PSchemaImage2],
        specifications: [
            { model: 'Z izolacją pokrywy, pozbawiona FCKW', size: '800 x 800', orderNumber: '188/I-P', internalSize: '686 x 795', overallSize: '980 x 980' },
            { model: 'Z wentylacją DN 150 i izolacją pokrywy, pozbawiona FCKW', size: '800 x 800', orderNumber: '188/ID-P', internalSize: '686 x 795', overallSize: '980 x 980' },
            { model: 'Z izolacją pokrywy, pozbawiona FCKW', size: '1000 x 1000', orderNumber: '111/I-P', internalSize: '886 x 995', overallSize: '1180 x 1180' },
            { model: 'Z wentylacją DN 150 i izolacją pokrywy, pozbawiona FCKW', size: '1000 x 1000', orderNumber: '111/ID-P', internalSize: '886 x 995', overallSize: '1180 x 1180' },
        ],
    },
    {
        id: 4,
        name: 'Właz SD3-RC3 o podwyższonej odporności, typ prostokątny',
        description: 'Właz zabezpieczony przed włamaniem, odporny na warunki atmosferyczne, o kształcie prostokątnym, wykonany ze stali nierdzewnej 1.4307 (AISI 304 L), zgodnie z normą DIN 1239:2018-4. Testowany w klasie odporności RC3 (DIN EN 1627), zgodnie z wymaganiami DVGW W 1050 - Ochrona obiektów wodociągowych. Odpowiedni do stosowania w strefie Ex 1.\n' +
            'Pokrywa składa się z płyty ze stali nierdzewnej o grubości 4 mm z centralnie podwyższonym profilem, ze stabilnymi ukrytymi zawiasami, amortyzatorami ze stali nierdzewnej, zintegrowanym zabezpieczeniem, z wymiennym uchwytem, z gumową uszczelką przymocowaną do spodniej strony pokrywy w celu zapewnienia niskiego zużycia, z systemem zamka z zabezpieczeniem przed przewierceniem, w standardzie z hartowanym zamkiem zabezpieczającym zgodnie z zaleceniami wydziałów dochodzeniowo-śledczych oraz zgodnie ze standardami normatywnymi; cylinder profilowy zgodny z normą DIN 18252.\n' +
            'Pokrywa i rama spawane łukowo, wytrawiane w kąpieli kwaśnej i pasywowane. Klucz obsługowy w zestawie.',
        columns: rectAngularManholeColumns,
        img: sd3RC3StandardImage,
        otherImgs: [sd3RC3StandardImage, sd3RC3VentImage, sd3RC3OpenImage],
        schemas: [sd3RC3SchemaImage1, sd3RC3SchemaImage2],
        specifications: [
            { model: 'Standard', size: '600 x 600', orderNumber: '166/GS-RC3', internalSize: '460 x 545', overallSize: '700 x 700' },
            { model: 'Standard', size: '800 x 800', orderNumber: '188/GS-RC3', internalSize: '660 x 745', overallSize: '900 x 900' },
            { model: 'Standard', size: '1000 x 1000', orderNumber: '111/GS-RC3', internalSize: '900 x 855', overallSize: '1100 x 1100' },
            { model: 'Z wentylacją DN 150', size: '600 x 600', orderNumber: '166/GDS-RC3', internalSize: '460 x 545', overallSize: '700 x 700' },
            { model: 'Z wentylacją DN 150', size: '800 x 800', orderNumber: '188/GDS-RC3', internalSize: '660 x 745', overallSize: '900 x 900' },
            { model: 'Z wentylacją DN 150', size: '1000 x 1000', orderNumber: '111/GDS-RC3', internalSize: '900 x 855', overallSize: '1100 x 1100' },
            { model: 'Z izolacją pokrywy, pozbawiona FCKW', size: '600 x 600', orderNumber: '166/IS-RC3', internalSize: '460 x 545', overallSize: '700 x 700' },
            { model: 'Z izolacją pokrywy, pozbawiona FCKW', size: '800 x 800', orderNumber: '188/IS-RC3', internalSize: '660 x 745', overallSize: '900 x 900' },
            { model: 'Z izolacją pokrywy, pozbawiona FCKW', size: '1000 x 1000', orderNumber: '111/IS-RC3', internalSize: '900 x 855', overallSize: '1100 x 1100' },
            { model: 'Z wentylacją DN 150 i izolacją pokrywy, pozbawiona FCKW', size: '600 x 600', orderNumber: '166/IDS-RC3', internalSize: '460 x 545', overallSize: '700 x 700' },
            { model: 'Z wentylacją DN 150 i izolacją pokrywy, pozbawiona FCKW', size: '800 x 800', orderNumber: '188/IDS-RC3', internalSize: '660 x 745', overallSize: '900 x 900' },
            { model: 'Z wentylacją DN 150 i izolacją pokrywy, pozbawiona FCKW', size: '1000 x 1000', orderNumber: '111/IDS-RC3', internalSize: '900 x 855', overallSize: '1100 x 1100' },
        ],
    },
    {
        id: 5,
        name: 'Właz SD4-RC3 o podwyższonej odporności, typ okrągły',
        description: 'Właz zabezpieczony przed włamaniem, odporny na warunki atmosferyczne, okrągły, wykonany ze stali nierdzewnej 1.4307 (AISI 304 L), zgodnie z normą DIN 1239:2018-4. Bezpieczeństwo sprawdzone w klasie odporności RC3 (DIN EN 1627), zgodnie z wymaganiami DVGW W 1050 - Ochrona obiektów obiektów wodociągowych. Odpowiedni do stosowania w strefie Ex 1.\n' +
            'Pokrywa składa się z płyty ze stali nierdzewnej o grubości 4 mm z centralnie podwyższonym profilem, ze stabilnymi ukrytymi zawiasami, amortyzatorami gazowymi ze stali nierdzewnej, zintegrowanym ogranicznikiem, z uchwytem, z wymienną uszczelką gumową odporną na warunki atmosferyczne, zapewniającą niskie zużycie, w tym system zamka z zabezpieczeniem przed przewierceniem, w standardzie z hartowanym zamkiem zabezpieczającym zgodnie z zaleceniami wydziałów dochodzeniowo-śledczych i zgodnie z normami; cylinder profilowy zgodny z normą DIN 18252.\n' +
            'Pokrywa i rama spawane łukowo, poddane obróbce w kąpieli kwaśnej i pasywowane.\n' +
            'Klucz obsługowy w zestawie.',
        columns: roundedManholeColumns,
        img: sd4RC3Image,
        otherImgs: [],
        schemas: [sd4RC3SchemaImage1, sd4RC3SchemaImage2],
        specifications: [
            { model: 'Standard', size: '600', orderNumber: '166/GRS-RC3 (1)', internalSize: '445', overallSize: '723' },
            { model: 'Standard', size: '800', orderNumber: '108/GRS-RC3', internalSize: '645', overallSize: '915' },
            { model: 'Standard', size: '1000', orderNumber: '101/GRS-RC3', internalSize: '845', overallSize: '1115' },
            { model: 'Z wentylacją DN 150', size: '600', orderNumber: '106/GDRS-RC3 (1)', internalSize: '445', overallSize: '723' },
            { model: 'Z wentylacją DN 150', size: '800', orderNumber: '108/GDRS-RC3', internalSize: '645', overallSize: '915' },
            { model: 'Z wentylacją DN 150', size: '1000', orderNumber: '101/GDRS-RC3', internalSize: '845', overallSize: '1115' },
            { model: 'Z izolacją pokrywy, pozbawiona FCKW', size: '600', orderNumber: '106/IRS-RC3 (1)', internalSize: '445', overallSize: '723' },
            { model: 'Z izolacją pokrywy, pozbawiona FCKW', size: '800', orderNumber: '108/IRS-RC3', internalSize: '645', overallSize: '915' },
            { model: 'Z izolacją pokrywy, pozbawiona FCKW', size: '1000', orderNumber: '101/IRS-RC3', internalSize: '845', overallSize: '1115' },
            { model: 'Z wentylacją DN 150 i izolacją pokrywy, pozbawiona FCKW', size: '600', orderNumber: '106/IDRS-RC3 (1)', internalSize: '445', overallSize: '723' },
            { model: 'Z wentylacją DN 150 i izolacją pokrywy, pozbawiona FCKW', size: '800', orderNumber: '108/IDRS-RC3', internalSize: '645', overallSize: '915' },
            { model: 'Z wentylacją DN 150 i izolacją pokrywy, pozbawiona FCKW', size: '1000', orderNumber: '101/IDRS-RC3', internalSize: '845', overallSize: '1115' },
        ],
    },
    {
        id: 6,
        name: 'Właz SD50, typ prostokątny',
        description: 'Właz w kształcie prostokąta, w całości wykonany ze stali nierdzewnej 1.4307 (AISI 304 L), zgodnie z normą DIN 1239. Odpowiedni do stosowania w strefie Ex 1.\n' +
            'Pokrywa składa się z blachy ryflowanej ze stali nierdzewnej, z zamknięciem śrubowym, w standardzie z hartowanym zamkiem zabezpieczającym, z uchwytem, amortyzatorami sprężynowymi ze stali nierdzewnej ze zintegrowanym ogranicznikiem; solidne, ukryte zawiasy.\n' +
            'Wymienna uszczelka gumowa przymocowana do spodniej strony pokrywy, zapewniająca niskie zużycie. W ramie wbudowane są otwory wentylacyjne, osłonięte siatką ze stali nierdzewnej.\n' +
            'Krata ochronna wykonana jest z profilu kątowego o nośności do 200 kg, zintegrowana z ramą, zlicowana z dolną krawędzią ramy (wysięg do 1000 mm), wyposażona w zawiasy i sprężyny gazowe ułatwiające otwieranie i zamykanie; ruszt ochronny i pokrywa składane w tym samym kierunku.\n' +
            'Pokrywa włazu, zabezpieczenie przed upadkiem i rama spawane łukowo, poddane obróbce w kąpieli kwaśnej i pasywowane.\n' +
            'Klucz obsługowy w zestawie.',
        columns: rectAngularManholeColumns,
        img: sd50Image,
        otherImgs: [sd50Image, sd50OpenImage],
        schemas: [sd50SchemaImage1, sd50SchemaImage2],
        specifications: [
            { model: 'Standard', size: '800 x 800', orderNumber: '', internalSize: '598 x 796', overallSize: '960 x 960' },
            { model: 'Standard', size: '1000 x 1000', orderNumber: '', internalSize: '798 x 996', overallSize: '1160 x 1160' },
        ],
    },
    {
        id: 7,
        name: 'Właz SD5 wodoszczelny, typ prostokątny',
        description: 'Właz wodoszczelny do głębokości 1 m wody, w kształcie prostokąta, w całości wykonany ze stali nierdzewnej 1.4307 (AISI 304 L), zgodnie z normą DIN 1239:2018-4. Odpowiedni do stosowania w strefie Ex 1.\n' +
            'Pokrywa wykonana z blachy nierdzewnej, z centralnie podwyższonym profilem, solidnymi zawiasami zewnętrznymi, amortyzatorem sprężynowym ze stali nierdzewnej, zintegrowanym zabezpieczeniem, uchwytem, gumową uszczelką, zamkiem i kapturkiem ochronnym z mosiądzu, który można otworzyć jedynie specjalnym kluczem.\n' +
            'Właz i rama spawane łukowo, poddane obróbce w kąpieli kwaśnej i pasywowane.\n' +
            'W zestawie materiał mocujący, uszczelka i klucz obsługowy.',
        columns: rectAngularManholeColumns,
        img: sd5WaterproofImage,
        otherImgs: [sd5WaterproofImage, sd5WaterproofOpenImage],
        schemas: [sd5SchemaImage1, sd5SchemaImage2],
        specifications: [
            { model: 'Standard', size: '600 x 600', orderNumber: '166/Ü (1)', internalSize: '525 x 555', overallSize: '785 x 755' },
            { model: 'Standard', size: '800 x 800', orderNumber: '188/Ü', internalSize: '725 x 755', overallSize: '985 x 955' },
            { model: 'Standard', size: '1000 x 1000', orderNumber: '111/Ü', internalSize: '925 x 955', overallSize: '1185 x 1155' },
            { model: 'Standard z izolacją', size: '600 x 600', orderNumber: '166/IÜ (1)', internalSize: '525 x 555', overallSize: '785 x 755' },
            { model: 'Standard z izolacją', size: '800 x 800', orderNumber: '188/IÜ', internalSize: '725 x 755', overallSize: '985 x 955' },
            { model: 'Standard z izolacją', size: '1000 x 1000', orderNumber: '111/IÜ', internalSize: '925 x 955', overallSize: '1185 x 1155' },
        ],
    },
    {
        id: 8,
        name: 'Właz SD6 wodoszczelny, typ okrągły',
        description: 'Właz wodoszczelna do głębokości 1 m wody, o okrągłym kształcie, w całości wykonany ze stali nierdzewnej 1.4307 (AISI 304 L), zgodnie z normą DIN 1239:2018-4. Odpowiedni do stosowania w strefie Ex 1.\n' +
            'Pokrywa wykonana z blachy nierdzewnej, z centralnie podwyższonym profilem, solidnymi zawiasami zewnętrznymi, amortyzatorem sprężynowym ze stali nierdzewnej, zintegrowanym odbojnikiem, klamką, uszczelką gumową, zamkiem i kapturkiem ochronnym z mosiądzu, który można otworzyć jedynie specjalnym kluczem.\n' +
            'Właz i rama spawane łukowo, poddane obróbce w kąpieli kwaśnej i pasywowane.\n' +
            'W zestawie materiał mocujący, uszczelka i klucz obsługowy.',
        columns: roundedManholeColumns,
        img: sd6WaterproofImage,
        otherImgs: [sd6WaterproofImage, sd6WaterproofOpenImage],
        schemas: [sd6SchemaImage1, sd6SchemaImage2],
        specifications: [
            { model: 'Standard', size: '600', orderNumber: '166/RÜ (1)', internalSize: '525', overallSize: '695' },
            { model: 'Standard', size: '800', orderNumber: '188/RÜ', internalSize: '725', overallSize: '895' },
            { model: 'Standard', size: '1000', orderNumber: '111/RÜ', internalSize: '925', overallSize: '1095' },
            { model: 'Standard z izolacją', size: '600', orderNumber: '166/IRÜ (1)', internalSize: '525', overallSize: '695' },
            { model: 'Standard z izolacją', size: '800', orderNumber: '188/IRÜ', internalSize: '725', overallSize: '895' },
            { model: 'Standard z izolacją', size: '1000', orderNumber: '111/IRÜ', internalSize: '925', overallSize: '1095' },
        ],
    },
    {
        id: 9,
        name: 'Właz SD7 o podwyższonej odporności, typ prostokątny',
        description: 'Właz montowany na równi z powierzchnią ruchu pieszego i/lub pojazdów, odporny na warunki atmosferyczne, zapachoszczelny, odporny na ataki, w kształcie prostokąta, wykonany ze stali nierdzewnej 1.4307 (AISI 304) L).\n' +
            'Certyfikowany w klasie odporności RC3 zgodnie z normą DIN EN 1627 w klasie D400, spełnia wymagania DVGW W 1050 - Ochrona obiektów obiektów wodociągowych. Odpowiedni do stosowania w strefie Ex 1.\n' +
            'Zaprojektowany dla szerokości do 1000 mm zgodnie z:\n' +
            '➤ DIN 1229 / DIN EN 124-1:2015-09 i DIN EN 124-3:2015-09, klasy A15, B125, D400, E600 z certyfikatem badań i zewnętrznym monitoringiem.\n' +
            'Zaprojektowany dla szerokości większej niż 1000 mm zgodnie z:\n' +
            '➤ DIN EN 1991:2010-12 (Eurokod 1) i DIN EN 1993:2010-12 (Eurokod 3), klasa EC10 i EC100.\n' +
            'Pokrywa składa się z blachy ryflowanej ze stali nierdzewnej, z dodatkowym usztywnieniem w zależności od ciężaru, zgodnie z normą DIN EN 124-1:2015-09, z uszczelką, zamkiem z zabezpieczeniem przeciw przewierceniu, zgodnie z zaleceniami organów dochodzeniowo-śledczych i zgodnie z normami. Mrozoodporne zawiasy wewnętrzne ze zintegrowanym zabezpieczeniem pokrywy.\n' +
            'Właz i rama spawane łukowo, poddane obróbce w kąpieli kwaśnej i pasywowane.\n' +
            'Klucz obsługowy w zestawie.',
        columns: rectAngularManholeColumns,
        img: sd7HighResistanceImage,
        otherImgs: [sd7HighResistanceImage, sd7HighResistanceKeyImage, sd7HighResistanceOpenImage],
        schemas: [sd7Schema1Image, sd7Schema2Image],
        specifications: [
            { model: 'Klasa A: 15 kN / 1.5 t', size: '800 x 800', orderNumber: 'ED 288/15-G', internalSize: '640 x 800', overallSize: '1000 x 878' },
            { model: 'Klasa A: 15 kN / 1.5 t', size: '1000 x 1000', orderNumber: 'ED 211/15-G', internalSize: '840 x 1000', overallSize: '1200 x 1078' },
            { model: 'Klasa B: 125 kN / 12.5 t', size: '800 x 800', orderNumber: 'ED 288/125-G', internalSize: '640 x 720', overallSize: '1000 x 878' },
            { model: 'Klasa B: 125 kN / 12.5 t', size: '1000 x 1000', orderNumber: 'ED 211/125-G', internalSize: '840 x 920', overallSize: '1200 x 878' },
            { model: 'Klasa D: 400 kN / 40 t', size: '800 x 800', orderNumber: 'ED 288/400-G', internalSize: '640 x 800', overallSize: '1000 x 878' },
            { model: 'Klasa D: 400 kN / 40 t', size: '1000 x 1000', orderNumber: 'ED 211/400-G', internalSize: '840 x 1000', overallSize: '1200 x 1078' },
            { model: 'Klasa D: 400 kN / 40 t', size: '800 x 1200', orderNumber: 'ED 2812/400-G', internalSize: '660 x 1080', overallSize: '1000 x 1278' },
            { model: 'Klasa E: 600 kN / 60 t', size: '800 x 800', orderNumber: 'ED 288/600-G', internalSize: '640 x 720', overallSize: '1000 x 878' },
            { model: 'Klasa E: 600 kN / 60 t', size: '1000 x 1000', orderNumber: 'ED 211/600-G', internalSize: '840 x 920', overallSize: '1200 x 1078' },
        ],
    },
    {
        id: 10,
        name: 'Właz SD7.R o podwyższonej odporności, typ okrągły',
        description: 'Właz montowany na równi z powierzchnią ruchu pieszego i/lub pojazdów, odporny na warunki atmosferyczne, zapachoszczelny, odporny na ataki, w kształcie okrągłym, wykonany ze stali nierdzewnej 1.4307 (AISI 304) L).\n' +
            'Certyfikowany w klasie odporności RC3 zgodnie z normą DIN EN 1627 w klasie D400, spełnia wymagania DVGW W 1050 - Ochrona obiektów obiektów wodociągowych. Odpowiedni do stosowania w strefie Ex 1.\n' +
            'Zaprojektowany dla szerokości do 1000 mm zgodnie z:\n' +
            '➤ DIN 1229 / DIN EN 124-1:2015-09 i DIN EN 124-3:2015-09, klasy A15, B125, D400, E600 z certyfikatem badań i zewnętrznym monitoringiem.\n' +
            'Zaprojektowany dla szerokości większej niż 1000 mm zgodnie z:\n' +
            '➤ DIN EN 1991:2010-12 (Eurokod 1) i DIN EN 1993:2010-12 (Eurokod 3), klasa EC10 i EC100.\n' +
            'Pokrywa składa się z blachy ryflowanej ze stali nierdzewnej, z dodatkowym usztywnieniem na dole w zależności od ciężaru, zgodnie z normą DIN EN 124-1:2015-09, z uszczelką, zamkiem z zabezpieczeniem przeciw przewierceniu, zgodnie z zaleceniami organów dochodzeniowo-śledczych i zgodnie z normami. Mrozoodporne zawiasy wewnętrzne ze zintegrowanym zabezpieczeniem pokrywy.\n' +
            'Właz i rama spawane łukowo, poddane obróbce w kąpieli kwaśnej i pasywowane.' +
            'Klucz obsługowy w zestawie.',
        columns: roundedManholeColumns,
        img: sd7_R_HighResistanceImage,
        otherImgs: [],
        schemas: [sd7_R_SchemaImage1, sd7_R_SchemaImage2],
        specifications: [
            { model: 'Klasa A: 15 kN / 1.5 t', size: '800', orderNumber: 'ED 208/15-GR', internalSize: '630', overallSize: '900' },
            { model: 'Klasa A: 15 kN / 1.5 t', size: '1000', orderNumber: 'ED 201/15-GR', internalSize: '830', overallSize: '1100' },
            { model: 'Klasa B: 125 kN / 12.5 t', size: '800', orderNumber: 'ED 208/125-GR', internalSize: '630', overallSize: '900' },
            { model: 'Klasa B: 125 kN / 12.5 t', size: '1000', orderNumber: 'ED 201/125-GR', internalSize: '830', overallSize: '1100' },
            { model: 'Klasa D: 400 kN / 40 t', size: '800', orderNumber: 'ED 208/400-GR', internalSize: '630', overallSize: '900' },
            { model: 'Klasa D: 400 kN / 40 t', size: '1000', orderNumber: 'ED 201/400-GR', internalSize: '830', overallSize: '1100' },
        ],
    },
    {
        id: 11,
        name: 'Właz SD7-RC4 o podwyższonej odporności, typ prostokątny',
        description: 'Właz montowany na równi z powierzchnią ruchu pieszego i/lub pojazdów, odporny na warunki atmosferyczne, zapachoszczelny, odporny na ataki, w kształcie prostokąta, wykonany ze stali nierdzewnej 1.4307 (AISI 304 L).\n' +
            'Certyfikowany w klasie odporności RC4 zgodnie z normą DIN EN 1627 w klasie B125, D400, E600, spełnia wymagania DVGW W 1050 - Ochrona obiektów obiektów wodociągowych. Odpowiedni do stosowania w strefie Ex 1.\n' +
            'Zaprojektowany dla szerokości do 1000 mm zgodnie z:\n' +
            '➤ DIN 1229 / DIN EN 124-1:2015-09 i DIN EN 124-3:2015-09, klasy B125, D400, E600 z certyfikatem badań i monitoringiem zewnętrznym.\n' +
            'Zaprojektowany dla szerokości większej niż 1000 mm zgodnie z:\n' +
            '➤ DIN EN 1991:2010-12 (Eurokod 1) i DIN EN 1993:2010-12 (Eurokod 3), klasa EC100.\n' +
            'Pokrywa składa się z blachy ryflowanej ze stali nierdzewnej, z dodatkowym usztywnieniem na dole w zależności od ciężaru, zgodnie z normą DIN EN 124-1:2015-09, z uszczelką, zamkiem z zabezpieczeniem przeciw przewierceniu, zgodnie z zaleceniami organów dochodzeniowo-śledczych i zgodnie z normami. Mrozoodporne zawiasy wewnętrzne ze zintegrowanym zabezpieczeniem pokrywy.\n' +
            'Właz i rama spawane łukowo, poddane obróbce w kąpieli kwaśnej i pasywowane.' +
            'Klucz obsługowy w zestawie.',
        columns: rectAngularManholeColumns,
        img: sd7_RC4HighResistanceImage,
        otherImgs: [],
        schemas: [sd7_RC4Schema1Image, sd7_RC4Schema2Image],
        specifications: [
            { model: 'Klasa B: 125 kN / 12.5 t', size: '800 x 800', orderNumber: 'ED 288/125-G', internalSize: '640 x 720', overallSize: '1000 x 878' },
            { model: 'Klasa B: 125 kN / 12.5 t', size: '1000 x 1000', orderNumber: 'ED 211/125-G', internalSize: '840 x 920', overallSize: '1200 x 878' },
            { model: 'Klasa D: 400 kN / 40 t', size: '800 x 800', orderNumber: 'ED 288/400-G', internalSize: '640 x 800', overallSize: '1000 x 878' },
            { model: 'Klasa D: 400 kN / 40 t', size: '1000 x 1000', orderNumber: 'ED 211/400-G', internalSize: '840 x 1000', overallSize: '1200 x 1078' },
            { model: 'Klasa E: 600 kN / 60 t', size: '800 x 800', orderNumber: 'ED 288/600-G', internalSize: '640 x 720', overallSize: '1000 x 878' },
            { model: 'Klasa E: 600 kN / 60 t', size: '1000 x 1000', orderNumber: 'ED 211/600-G', internalSize: '840 x 920', overallSize: '1200 x 1078' },
        ],
    },
    {
        id: 12,
        name: "Właz SD11 o lekkiej konstrukcji",
        description: "Właz montowany na równi z powierzchnią ruchu, odporny na warunki atmosferyczne, w kształcie prostokąta, nośność do 500 kg/m², wykonany ze stali nierdzewnej 1.4307 (AISI 304 L).\n" +
            "Pokrywa składa się z blachy ryflowanej ze stali nierdzewnej, z dodatkowym usztywnieniem od dołu zgodnie z wymaganiami statycznymi, certyfikowaną antypoślizgowością i uszczelką.\n" +
            "Właz i rama spawane łukowo, poddane obróbce w kąpieli kwaśnej i pasywowane." +
            "Klucz obsługowy w zestawie.",
        columns: rectAngularManholeColumns,
        img: sd11LightweightImage,
        otherImgs: [],
        schemas: [sd11Schema_a_Image, sd11Schema_b_Image, sd11Schema_c_Image, sd11Schema_d_Image, sd11SchemaImage1, sd11SchemaImage2],
        specifications: [
            { model: "Standard, mocowanie na felc", size: "800 x 800", "orderNumber": "SD11.1 800x800", "internalSize": "792 x 680", "overallSize": "920 x 920" },
            { model: "Standard, mocowanie na felc", size: "1000 x 1000", "orderNumber": "SD11.1 1000x1000", "internalSize": "992 x 880", "overallSize": "1120 x 1120" },
            { model: "Standard, mocowanie za pomocą kołków mocujących", size: "800 x 800", orderNumber: "SD11.1 800x800 AD", internalSize: "670 x 550", overallSize: "790 x 790" },
            { model: "Standard, mocowanie za pomocą kołków mocujących", size: "1000 x 1000", orderNumber: "SD11.1 1000x1000 AD", internalSize: "870 x 750", overallSize: "990 x 990" }
        ],
        additionalSpecifications: [
            { model: "Standard z amortyzatorami, mocowanie na felc", size: "800 x 800", orderNumber: "SD11.1 800x800 GDF", internalSize: "800 x 685", overallSize: "920 x 920" },
            { model: "Standard z amortyzatorami, mocowanie na felc", size: "1000 x 1000", orderNumber: "SD11.1 1000x1000 GDF", internalSize: "845 x 940", overallSize: "1120 x 1120" },
            { model: "Standard amortyzatorami, mocowanie za pomocą kołków mocujących", size: "800 x 800", orderNumber: "SD11.1 800x800 AD GDF", internalSize: "670 x 555", overallSize: "790 x 790" },
            { model: "Standard amortyzatorami, mocowanie za pomocą kołków mocujących", size: "1000 x 1000", orderNumber: "SD11.1 1000x1000 AD GDF", internalSize: "870 x 755", overallSize: "990 x 990" }
        ]
        },
    {
        id: 13,
        name: "Właz SD12 przesuwny",
        description: "Właz przesuwny, w całości wykonany ze stali nierdzewnej 1.4307 (AISI 304 L), o gładkim wykończeniu.\n" +
            "Pokrywa wykonana jest ze stabilnej płyty ze stali nierdzewnej i wyposażona w rolki z tworzywa sztucznego. Osłona montowana jest w prowadnicy zewnętrznej i nie można jej zdjąć. Ryglowanie w stanie zamkniętym możliwe jest za pomocą zamka cylindrycznego.\n" +
            "Pokrywa i szyny spawane łukowo, poddane obróbce w kąpieli kwaśnej i pasywowane.\n" +
            "Klucz obsługowy w zestawie.\n" +
            "Wymiary według indywidualnych wymagań.\n" +
            "Dostępne opcje:\n" +
            "A) wersja prosta\n" +
            "B) wersja okrągła",
        img: sd12SlidingImage,
        otherImgs: [sd12SlidingImage, sd12SlidingOpenImage],
        schemas: [sd12SchemaImage],
        specifications: []
    }
]

export const technicalDoorsProducts = [
    {
        id: 1,
        name: "Drzwi techniczne TT1.1",
        description: "Drzwi techniczne z przeznaczeniem do budynków w zakresie zaopatrzenia w wodę pitną i odprowadzania ścieków. Produkt gotowy do montażu. Drzwi są jednoskrzydłowe, ocieplane, w całości wykonane ze stali nierdzewnej 1.4307 (AISI 304 L), z podwójną uszczelką gumową, zamknięcie drzwi i ościeżnicy zlicowane, otwarcie drzwi na zewnątrz (w kierunku ewakuacji).\n" +
            "Mocowanie do ościeżnicy za pomocą stabilnych zawiasów. Zamek zgodny z normą DIN 18251, klasa 3, o podwyższonej ochronie antykorozyjnej. \n" +
            "Rama i drzwi spawane łukowo oraz poddane obróbce w kąpieli kwaśnej i pasywowane.\n" +
            "UWAGA: Jeśli drzwi zostaną wystawione na działanie promieni słonecznych, może to mieć wpływ na ich działanie. W takim przypadku zaleca się wyposażenie drzwi od zewnątrz w boazerię drewnianą lub blachę stalową.",
        img: TTI1_1StandardAImage,
        otherImgs: [TTI1_1StandardAImage, TTI1_1StandardBImage],
        schemas: [TTI1_1SchemaImage],
        specifications: [],
    },
    {
        id: 2,
        name: "Drzwi techniczne TT1.1 W",
        description: "Drzwi techniczne z przeznaczeniem do budynków / komór wodnych. Produkt gotowy do montażu. Drzwi są jednoskrzydłowe, ocieplane, w całości wykonane ze stali nierdzewnej 1.4404 (AISI 316 L), z podwójną uszczelką gumową, zamknięcie drzwi i ościeżnicy zlicowane, otwarcie drzwi na zewnątrz (w kierunku ewakuacji).\n" +
            "Mocowanie do ościeżnicy za pomocą stabilnych zawiasów. Zamek wykonany ze stali nierdzewnej zgodnie z normą DIN 18251, klasa 3, zamykany tylko od strony suchej. Klamka ze stali nierdzewnej od zewnątrz i od wewnątrz.\n" +
            "Ościeżnica wykonana ze specjalnego kształtownika, z uszczelką ościeżnicy. Zgodność z normami KTW i DVGW W270. Rama i drzwi spawane łukowo oraz poddane obróbce w kąpieli kwaśnej i pasywowane."
            ,
        img: TTI1_1_WStandardImage,
        otherImgs: [TTI1_1_WStandardImage, TTI1_1_WOpenImage],
        schemas: [TTI1_1_WSchemaImage],
        specifications: []
    },
    {
        id: 3,
        name: "Drzwi techniczne TT1.2",
        description: "\"Drzwi techniczne z przeznaczeniem do budynków w zakresie zaopatrzenia w wodę pitną i odprowadzania ścieków. Produkt gotowy do montażu. Drzwi są dwuskrzydłowe, ocieplane, w całości wykonane ze stali nierdzewnej 1.4307 (AISI 304 L). Posiadają podwójną uszczelką gumową, a skrzydła drzwi i ościeżnica są zlicowane. Drzwi otwierają się na zewnątrz (w kierunku ewakuacji).\n" +
            "Mocowanie do ościeżnicy za pomocą stabilnych zawiasów. Zamek wykonany zgodnie z normą DIN 18251, klasa 3, o podwyższonej ochronie antykorozyjnej. \n" +
            "Sterowanie skrzydłem stałym odbywa się za pomocą przesuwnego rygla. Powierzchnia styku skrzydła otwieranego ze skrzydłem stałym jest uszczelniona.\n" +
            "Rama i drzwi spawane łukowo oraz poddane obróbce w kąpieli kwaśnej i pasywowane. UWAGA: Jeśli drzwi zostaną wystawione na działanie promieni słonecznych, może to mieć wpływ na ich działanie. W takim przypadku zaleca się wyposażenie drzwi od zewnątrz w boazerię drewnianą lub blachę stalową.",
        img: TTI1_2StandardImage1,
        otherImgs: [TTI1_2StandardImage1, TTI1_2StandardImage2],
        schemas: [TTI1_2SchemaImage],
        specifications: []
    },
    {
        id: 4,
        name: "Drzwi techniczne antywłamaniowe TT2.1/RC3",
        description: "Drzwi techniczne antywłamaniowe z przeznaczeniem do budynków w zakresie zaopatrzenia w wodę pitną, wyposażone w system alarmowy. Wykonanie zgodnie z klasą bezpieczeństwa RC3, która zalecana jest przez wydział kryminalny.\n" +
            "Produkt gotowy do montażu. Drzwi są jednoskrzydłowe, ocieplane, w całości wykonane ze stali nierdzewnej 1.4307 (AISI 304 L). Posiadają podwójną uszczelką gumową, drzwi i ościeżnica są zlicowane, otwieranie na zewnątrz (w kierunku ewakuacji). Izolacja akustyczna: 34/29 dB.\n" +
            "Mocowanie do ościeżnicy za pomocą stabilnych zawiasów. Zgodność z normą DIN EN 1627, DIN 18252:2006-12,21-,31-,71-BZ), z zamkiem antypanicznym, co oznacza, że drzwi można w każdej chwili otworzyć od wewnątrz za pomocą klamki, nawet gdy są zamknięte.\n" +
            "Rama i drzwi spawane łukowo oraz poddane obróbce w kąpieli kwaśnej i pasywowane. UWAGA: Jeśli drzwi zostaną wystawione na działanie promieni słonecznych, może to mieć wpływ na ich działanie. W takim przypadku zaleca się wyposażenie drzwi od zewnątrz w boazerię drewnianą lub blachę stalową.",
        img: TTI2_1_RC3StandardImage1,
        otherImgs: [TTI2_1_RC3StandardImage1, TTI2_1_RC3StandardImage2],
        schemas: [TTI2_1_RC3SchemaImage],
        specifications: []
    },
    {
        id: 5,
        name: "Drzwi techniczne antywłamaniowe TT2.2/RC3",
        description: "Drzwi techniczne antywłamaniowe z przeznaczeniem do budynków w zakresie zaopatrzenia w wodę pitną, wyposażone w system alarmowy. Wykonanie zgodnie z klasą bezpieczeństwa RC3, która zalecana jest przez wydział kryminalny.\n" +
            "Produkt gotowy do montażu. Drzwi są dwuskrzydłowe, ocieplane, w całości wykonane ze stali nierdzewnej 1.4307 (AISI 304 L). Posiadają podwójną uszczelkę gumową, drzwi i ościeżnica są zlicowane, otwieranie na zewnątrz (w kierunku ewakuacji). Izolacja akustyczna: 34/29 dB.\n" +
            "Mocowanie do ościeżnicy za pomocą stabilnych zawiasów. Zgodność z normą DIN EN 1627, DIN 18252:2006-12,21-,31-,71-BZ). Drzwi mają zamek antypanicznym, co oznacza, że można je w każdej chwili otworzyć od wewnątrz za pomocą klamki, nawet gdy są zamknięte.\n" +
            "Rama i drzwi spawane łukowo oraz poddane obróbce w kąpieli kwaśnej i pasywowane. UWAGA: Jeśli drzwi zostaną wystawione na działanie promieni słonecznych, może to mieć wpływ na ich działanie. W takim przypadku zaleca się wyposażenie drzwi od zewnątrz w boazerię drewnianą lub blachę stalową.",
        img: TTI2_2_RC3StandardImage1,
        otherImgs: [TTI2_2_RC3StandardImage1, TTI2_2_RC3StandardImage2],
        schemas: [TTI2_2_RC3SchemaImage],
        specifications: []
    },
    {
        id: 6,
        name: "Drzwi techniczne antywłamaniowe TT2.1/RC4",
        description: "Drzwi techniczne antywłamaniowe z przeznaczeniem do budynków w zakresie zaopatrzenia w wodę pitną. Klasa bezpieczeństwa RC4 - zalecana przez wydział dochodzeniowo-śledczy w przypadku, gdy budynek nie jest zabezpieczony systemem antywłamaniowym. Produkt gotowy do montażu. Drzwi są jednoskrzydłowe, ocieplane, w całości wykonane ze stali nierdzewnej 1.4307 (AISI 304 L). Posiadają podwójną uszczelką gumową, drzwi i ościeżnica są zlicowane, otwieranie na zewnątrz (w kierunku ewakuacji). Izolacja akustyczna: 34/29 dB.\n" +
            "Mocowane do ościeżnicy za pomocą stabilnych zawiasów. Zgodność z normą DIN EN 1627, DIN 18252:2006-1242-,82-BZ. Drzwi mają zamek antypanicznym, co oznacza, że można je w każdej chwili otworzyć od wewnątrz za pomocą klamki, nawet gdy są zamknięte.\n" +
            "Rama i drzwi spawane łukowo oraz poddane obróbce w kąpieli kwaśnej i pasywowane. UWAGA: Jeśli drzwi zostaną wystawione na działanie promieni słonecznych, może to mieć wpływ na ich działanie. W takim przypadku zaleca się wyposażenie drzwi od zewnątrz w boazerię drewnianą lub blachę stalową.",
        img: TTI2_1_RC4StandardImage1,
        otherImgs: [TTI2_1_RC4StandardImage1, TTI2_1_RC4StandardImage2],
        schemas: [TTI2_1_RC4SchemaImage],
        specifications: []
    },
    {
        id: 7,
        name: "Drzwi techniczne antywłamaniowe TT2.2/RC4",
        description: "Drzwi techniczne antywłamaniowe z przeznaczeniem do budynków w zakresie zaopatrzenia w wodę pitną. Klasa bezpieczeństwa RC4 - zalecana przez wydział dochodzeniowo-śledczy w przypadku, gdy budynek nie jest zabezpieczony systemem antywłamaniowym. Produkt gotowy do montażu. Drzwi są dwuskrzydłowe, ocieplane, w całości wykonane ze stali nierdzewnej 1.4307 (AISI 304 L). Posiadają podwójną uszczelką gumową, drzwi i ościeżnica są zlicowane, otwieranie na zewnątrz (w kierunku ewakuacji). Izolacja akustyczna: 34/29 dB.\n" +
            "Mocowane do ościeżnicy za pomocą stabilnych zawiasów. Zgodność z normą DIN EN 1627, DIN 18252:2006-1242-,82-BZ. Drzwi mają zamek antypanicznym, co oznacza, że można je w każdej chwili otworzyć od wewnątrz za pomocą klamki, nawet gdy są zamknięte.\n" +
            "Rama i drzwi spawane łukowo oraz poddane obróbce w kąpieli kwaśnej i pasywowane. UWAGA: Jeśli drzwi zostaną wystawione na działanie promieni słonecznych, może to mieć wpływ na ich działanie. W takim przypadku zaleca się wyposażenie drzwi od zewnątrz w boazerię drewnianą lub blachę stalową.",
        img: TTI2_2_RC4StandardImage,
        otherImgs: [],
        schemas: [TTI2_2_RC4SchemaImage],
        specifications: []
    },
    {
        id: 8,
        name: "Drzwi dedykowane dla chlorowni TT5",
        description: "Drzwi przeznaczone są do budynków zaopatrzonych w wodę pitną, w których magazynowany lub używany jest chlor gazowy. Produkt gotowy do montażu. Drzwi są jedno lub dwuskrzydłowe, ocieplane, w całości wykonane ze stali nierdzewnej 1.4404 (AISI 316 L). Posiadają podwójną uszczelkę gumową, szczeliny wentylacyjne, drzwi i ościeżnica są zlicowane, otwierają się na zewnątrz (w kierunku ewakuacji). Zgodność ze standardami UVV i DVGW.\n" +
            "Mocowane do ościeżnicy za pomocą stabilnych zawiasów. Drzwi mają zamek antypanicznym, co oznacza, że można je w każdej chwili otworzyć od wewnątrz za pomocą klamki, nawet gdy są zamknięte. W komplecie klamki ze stali nierdzewnej, przycisk na zewnątrz, klamka wewnątrz.\n" +
            "Rama i drzwi spawane łukowo oraz poddane obróbce w kąpieli kwaśnej i pasywowane. UWAGA: Jeśli drzwi zostaną wystawione na działanie promieni słonecznych, może to mieć wpływ na ich działanie. W takim przypadku zaleca się wyposażenie drzwi od zewnątrz w boazerię drewnianą lub blachę stalową.",
        img: TT5StandardImage1,
        otherImgs: [TT5StandardImage1, TT5StandardImage2],
        schemas: [TT5SchemaImage],
        specifications: []
    },
    {
        id: 9,
        name: "Drzwi zewnętrzne przeciwpowodziowe TT6",
        description: "Drzwi przeznaczone są do budynków zaopatrzonych w wodę pitną, zwłaszcza na obszarach, gdzie istnieje ryzyko powodzi. Drzwi są ocieplane, wodoszczelne do głębokości 2 m, otwierane na zewnątrz (strona ciśnieniowa). Produkt gotowy do montażu, w całości wykonany ze stali nierdzewnej 1.4307 (AISI 304 L).\n" +
            "Mocowanie do ościeżnicy za pomocą stabilnych zawiasów. Dźwignie blokujące obsługiwane z obu stron.\n" +
            "Ściana w miejscu montażu musi mieć płaską powierzchnię, tolerancja: ± 1 mm na metr, minimalna wymagana klasa wytrzymałości betonu to C20/25; maksymalna dopuszczalna klasa wytrzymałości betonu C50/60.\n" +
            "Rama i drzwi spawane łukowo oraz poddane obróbce w kąpieli kwaśnej i pasywowane.",
        columns: doorsColumnsDefault,
        img: TT6StandardImage1,
        otherImgs: [TT6StandardImage1, TT6StandardImage2],
        schemas: [TT6SchemaImage],
        specifications: [
            {orderNumber: "0830/1630", internalSize: "830 / 1630", overallSize: "800 / 1600"},
            {orderNumber: "0830/1630", internalSize: "830 / 1630", overallSize: "800 / 1600"},
            {orderNumber: "0830/1630", internalSize: "830 / 1630", overallSize: "800 / 1600"},
        ]
    },
    {
        id: 10,
        name: "Drzwi  TT7 z dźwigniami ryglującymi",
        description: "Drzwi przeznaczone do zbiorników, zwłaszcza w zakresie zaopatrzenia w wodę pitną, szczelne do wysokości 10 m wody. Produkt gotowy do montażu, otwieranie na stronę tłoczną, w całości wykonany ze stali nierdzewnej 1.4404 (AISI 316) L).\n" +
            "Zgodność z normami KTW i DVGW W 270. Dźwignie ryglujące obsługiwane zarówno od zewnątrz drzwi, jak i ze względów bezpieczeństwa także od strony ciśnieniowej, gdy zbiornik jest pusty. Mocowanie do ościeżnicy za pomocą stabilnych zawiasów.\n" +
            "Rama i drzwi spawane łukowo oraz poddane obróbce w kąpieli kwaśnej i pasywowane. Uwaga: Gwarancja szczelności pomiędzy ramą a betonem jest zapewniona tylko wtedy, gdy konstrukcja betonu jest jednorodna na całym obwodzie. Minimalną jakość betonu i odpowiednią klasę betonu dobiera klient. W przypadku ram przeznaczonych do późniejszego montażu należy przestrzegać wymagań producenta dotyczących materiałów mocujących.",
        columns: doorsColumnsDefault,
        img: TT7StandardImage1,
        otherImgs: [TT7StandardImage1, TT7StandardImage2],
        schemas: [TT7SchemaImage1, TT7SchemaImage2],
        specifications: [
            {orderNumber: "0800/1200", internalSize: "800 / 1200", overallSize: "1086 / 1486"},
            {orderNumber: "0800/1600", internalSize: "800 / 1600", overallSize: "1086 / 1886"},
            {orderNumber: "0800/1800", internalSize: "800 / 1800", overallSize: "1086 / 2086"},
            {orderNumber: "1000/2000", internalSize: "1000 / 2000", overallSize: "1290 / 2290"},
        ]
    },
    {
        id: 11,
        name: "Drzwi ciśnieniowe TT7.Z z zamkiem centralnym",
        description: "Drzwi przeznaczone do zbiorników, zwłaszcza w zakresie zaopatrzenia w wodę pitną, szczelne do wysokości 10 m wody. Produkt gotowy do montażu, otwieranie na stronę tłoczną, w całości wykonany ze stali nierdzewnej 1.4404 (AISI 316) L).\n" +
            "Zgodność z normami KTW i DVGW W 270. Dźwignie ryglujące obsługiwane zarówno od zewnątrz drzwi, jak i ze względów bezpieczeństwa także od strony ciśnieniowej przy pustym zbiorniku. Mocowanie do ościeżnicy za pomocą stabilnych zawiasów.\n" +
            "Rama i drzwi spawane łukowo oraz poddane obróbce w kąpieli kwaśnej i pasywowane. Uwaga: Gwarancja szczelności pomiędzy ramą a zbiornikiem jest zapewniona tylko wtedy, gdy konstrukcja jest jednorodna na całym obwodzie.",
        columns: doorsColumnsDefault,
        img: TT7ZStandardImage,
        otherImgs: [],
        schemas: [TT7ZSchemaImage1, TT7ZSchemaImage2],
        specifications: [
            {orderNumber: "0800/1200", internalSize: "800 / 1200", overallSize: "1086 / 1486"},
            {orderNumber: "0800/1600", internalSize: "800 / 1600", overallSize: "1086 / 1886"},
            {orderNumber: "0800/1800", internalSize: "800 / 1800", overallSize: "1086 / 2086"},
            {orderNumber: "1000/2000", internalSize: "1000 / 2000", overallSize: "1290 / 2290"},
        ]
    },
    {
        id: 12,
        name: "Drzwi ciśnieniowe TT7.30 z zamkiem centralnym",
        description: "Drzwi przeznaczone do zbiorników, zwłaszcza w zakresie zaopatrzenia w wodę pitną, szczelne do wysokości 30 m wody. Produkt gotowy do montażu, otwieranie na stronę tłoczną, w całości wykonany ze stali nierdzewnej 1.4404 (AISI 316) L). \n" +
            "Zgodność z normami KTW i DVGW W 270. Dźwignie ryglujące obsługiwane zarówno od zewnątrz drzwi, jak i ze względów bezpieczeństwa także od strony ciśnieniowej przy pustym zbiorniku. Rama i drzwi spawane łukowo oraz poddane obróbce w kąpieli kwaśnej i pasywowane. Uwaga: Gwarancja szczelności pomiędzy ramą a zbiornikiem jest zapewniona tylko wtedy, gdy konstrukcja jest jednorodna na całym obwodzie.",
        columns: doorsColumnsModernization,
        img: TT30StandardImage,
        otherImgs: [],
        schemas: [TT30SchemaImage],
        specifications: [
            {orderNumber: "0800/0800", internalSize: "800 / 800", overallSize: "1190 / 1190", internalSizeModernize: "800 / 800", overallSizeModernize: "830 / 830"},
            {orderNumber: "0800/1200", internalSize: "800 / 1200", overallSize: "1190 / 1590", internalSizeModernize: "800 / 1200", overallSizeModernize: "830 / 1230\n"},
            {orderNumber: "0800/1600", internalSize: "800 / 1600", overallSize: "1190 / 1990", internalSizeModernize: "800 / 1600", overallSizeModernize: "830 / 1630\n0"},
            {orderNumber: "0800/1800", internalSize: "800 / 1800", overallSize: "1190 / 2190", internalSizeModernize: "800 / 1800", overallSizeModernize: "830 / 1830\n"},
            {orderNumber: "1000/2000", internalSize: "1000 / 2000", overallSize: "1390 / 2390", internalSizeModernize: "1000 / 2000", overallSizeModernize: "1030 / 2030\n"}
        ]
    },
    {
        id: 13,
        name: "Drzwi ciśnieniowe TT7.R z zamkami śrubowymi",
        description: "Drzwi okrągłe przeznaczone do zbiorników, zwłaszcza w zakresie zaopatrzenia w wodę pitną, szczelne do wysokości 10 m wody. Produkt gotowy do montażu, otwieranie na stronę tłoczną, w całości wykonany ze stali nierdzewnej 1.4404 (AISI 316) L). Drzwi charakteryzują się wygiętym profilem w celu zwiększenia wytrzymałości ciśnieniowej.\n" +
            "Zgodność z normami KTW i DVGW W 270. Ryglowanie za pomocą zamków śrubowych obsługiwanych tylko od strony suchej. Rama i drzwi spawane łukowo oraz poddane obróbce w kąpieli kwaśnej i pasywowane. Uwaga: Gwarancja szczelności pomiędzy ramą a zbiornikiem jest zapewniona tylko wtedy, gdy konstrukcja jest jednorodna na całym obwodzie.",
        columns: doorsColumnsTank,
        img: TT7RStandardImage,
        otherImgs: [],
        schemas: [TT7RSchemaImage],
        specifications: [
            {orderNumber: "600", internalSize: "617", overallSize: "710"},
            {orderNumber: "800", internalSize: "817", overallSize: "910"},
            {orderNumber: "1000", internalSize: "1017", overallSize: "1110"},
        ]
    },
]

export const equipmentLaddersProducts = [
    {
        id: 1,
        name: "Składane przedłużenie drabiny EH K",
        description: "Konstrukcja służąca jako pomoc przy wejściu na drabinę wykonana została ze stali nierdzewnej 1.4307 (AISI 304 L) z przeznaczeniem do drabin o szerokości 300 mm, 400 mm, 500 mm.\n" +
            "Zgodność z normą DIN 3620." +
            "Uwaga: W tym wariancie konstrukcji przedłużenia drabiny nie można uzyskać wymaganego zgodnie z normą DIN 19572 wysięgu 1,1 m.\n" +
            "Szerokość włazu = mm [w przypadku włazów okrągłych, możliwość zastosowania tylko drabin o szerokości 300 mm]. Konstrukcja poddana obróbce w kąpieli kwaśnej i pasywowana.\"\t\t\t\t\n",
        img: EH_K_StandardImage1,
        otherImgs: [EH_K_StandardImage1, EH_K_StandardImage2],
        schemas: [EH_K_SchemaImage],
        specifications: []
    },
    {
        id: 2,
        name: "Składane przedłużenie drabiny z uchwytem EH KV",
        description: "Konstrukcja służąca jako pomoc przy wejściu na drabinę wraz z wysuwanym uchwytem, wykonana została ze stali nierdzewnej 1.4307 (AISI 304 L), z przeznaczeniem do drabin  o szerokości 300 mm, 400 mm, 500 mm.\n" +
            "Zgodność z normą DIN 19572.\n" +
            "Szerokość włazu = mm. [Wymagana minimalna szerokość włazu: > 800 mm. W przypadku włazów okrągłych możliwe jest zastosowanie wyłącznie drabin o szerokości 300 mm].\n" +
            "Konstrukcja poddana obróbce w kąpieli kwaśnej i pasywowana.",
        img: EH_KV_StandardImage1,
        otherImgs: [EH_KV_StandardImage1, EH_KV_StandardImage2],
        schemas: [EH_KV_SchemaImage],
        specifications: []
    },
    {
        id: 3,
        name: "Zakładane przedłużenie drabiny EH S",
        description: "Konstrukcja służąca jako pomoc przy wejściu na drabinę wykonana została ze stali nierdzewnej 1.4307 (AISI 304 L), z przeznaczeniem do drabin o szerokości 300 mm, 400 mm, 500 mm.\n" +
            "Długość całkowita: 1200 mm.\n" +
            "Zgodność z normą DIN 19572.\n" +
            "Szerokość drabiny = mm.\n" +
            "Przedłużenie posiada przyspawane haki, dzięki którym możliwe jest połączenie z górnym szczeblem drabiny znajdującej się wewnątrz włazu.\n" +
            "Konstrukcja poddana obróbce w kąpieli kwaśnej i pasywowana.",
        img: EH_KS_StandardImage,
        otherImgs: [],
        schemas: [EH_KS_SchemaImage],
        specifications: []
    },
    {
        id: 4,
        name: "Poręcz do drabiny EH VSD",
        description: "Poręcz wykonana została ze stali nierdzewnej 1.4307 (AISI 304 L), z przeznaczeniem do drabin o szerokości 300 mm, 400 mm, 500 mm i długości od 2010 mm.\n" +
            "Zgodność z normą DIN 19572.\n" +
            "Szerokość drabiny = mm, Długość drabiny = m. Długość całkowita, to 1800 mm.\n" +
            "Konstrukcja poddana obróbce w kąpieli kwaśnej i pasywowana.",
        img: EH_VSD_StandardImage1,
        otherImgs: [EH_VSD_StandardImage1,EH_VSD_StandardImage2],
        schemas: [EH_VSD_SchemaImage],
        specifications: []
    },
    {
        id: 5,
        name: "Uchwyt do drabiny EH VS",
        description: "Składany uchwyt do drabiny wykonany został ze stali nierdzewnej 1.4404 (AISI 316 L). Dostępny w wariancie wyjmowanym i przenośnym lub stacjonarnym. Zgodność z normą DIN 19572.\n" +
            "Istnieje możliwość regulacji uchwytu.\n" +
            "Długość całkowita: 1500 mm.\n" +
            "Konstrukcja poddana obróbce w kąpieli kwaśnej i pasywowana.",
        img: EH_VS_StandardImage,
        otherImgs: [],
        schemas: [EH_VS_SchemaImage],
        specifications: []
    },
    {
        id: 6,
        name: "Poręcz wejściowa SiS5 HB",
        description: "Poręcz w całości wykonana ze stali nierdzewnej 1.4307 (AISI 304 L).\n" +
            "Składa się z giętej rury i schodków do mocowania na ścianie.\n" +
            "Konstrukcja poddana obróbce w kąpieli kwaśnej i pasywowana.",
        columns: equipmentLadders1,
        img: SiS5_HB_StandardImage1,
        otherImgs: [SiS5_HB_StandardImage1, SiS5_HB_StandardImage2],
        schemas: [SiS5_HB_SchemaImage],
        specifications: [
            { type: 'Typ A', orderNumber: 'HEB A'},
            { type: 'Typ B', orderNumber: 'HEB B'},
            { type: 'Typ C', orderNumber: 'HEB C'},
        ]
    },
    {
        id: 7,
        name: "Drabina z systemem zabezpieczenia SiS2",
        description: "Drabina z wbudowanym systemem zabezpieczenia wykonana została ze stali nierdzewnej 1.4307 (AISI 304 L). Zgodność z certyfikatem konstrukcyjnym i oznakowaniem CE oraz normą DIN EN 14396.\n" +
            "Uchwyty wykonane zostały ze specjalnego profilu o dużej sztywności i pokryte kapturkami z PCV.\n" +
            "Szczeble wykonane z profilu U z perforowaną powierzchnią, antypoślizgowe. Wysokość stopnia: 280 mm, szerokość drabiny w świetle: 400 mm lub 500 mm. W zestawie uchwyty do mocowania do ściany. Konstrukcja poddana obróbce w kąpieli kwaśnej i pasywowana.",
        columns: equipmentLadders2,
        img: SiS2_StandardImage1,
        otherImgs: [SiS2_StandardImage1, SiS2_StandardImage2, SiS2_StandardImage3],
        schemas: [SiS2_SchemaImage],
        specifications: [
            { height: '2,85', steps: '11', orderNumberWidth400: 'SFA4 2850', orderNumberWidth500: 'SFA5 2850'},
            { height: '3,13', steps: '12', orderNumberWidth400: 'SFA4 3130', orderNumberWidth500: 'SFA5 3130'},
            { height: '3,41', steps: '13', orderNumberWidth400: 'SFA4 3410', orderNumberWidth500: 'SFA5 3410'},
            { height: '3,69', steps: '14', orderNumberWidth400: 'SFA4 3690', orderNumberWidth500: 'SFA5 3690'},
            { height: '3,97', steps: '15', orderNumberWidth400: 'SFA4 3970', orderNumberWidth500: 'SFA5 3970'},
            { height: '4,25', steps: '16', orderNumberWidth400: 'SFA4 4250', orderNumberWidth500: 'SFA5 4250'},
            { height: '4,53', steps: '17', orderNumberWidth400: 'SFA4 4530', orderNumberWidth500: 'SFA5 4530'},
            { height: '4,81', steps: '18', orderNumberWidth400: 'SFA4 4810', orderNumberWidth500: 'SFA5 4810'},
            { height: '5,09', steps: '19', orderNumberWidth400: 'SFA4 5090', orderNumberWidth500: 'SFA5 5090'},
            { height: '5,37', steps: '20', orderNumberWidth400: 'SFA4 5370', orderNumberWidth500: 'SFA5 5370'},
            { height: '5,65', steps: '21', orderNumberWidth400: 'SFA4 5650', orderNumberWidth500: 'SFA5 5650'},
            { height: '5,93', steps: '22', orderNumberWidth400: 'SFA4 5930', orderNumberWidth500: 'SFA5 5930'},
            { height: '6,21', steps: '23', orderNumberWidth400: 'SFA4 6210', orderNumberWidth500: 'SFA5 6210'},
        ]
    },
    {
        id: 8,
        name: "Drabina z systemem zabezpieczenia SiS3",
        description: "Drabina z wbudowanym systemem zabezpieczenia wykonana została ze stali nierdzewnej 1.4307 (AISI 304 L). Zgodność z certyfikatem konstrukcyjnym i oznakowaniem CE oraz normą DIN EN 14396.\n" +
            "Szczeble wykonane w kształcie litery U z perforowaną powierzchnią, antypoślizgowe, z bocznymi ogranicznikami poślizgu. W zestawie kątowniki do mocowania na ścianie. Konstrukcja poddana obróbce w kąpieli kwaśnej i pasywowana.",
        columns: equipmentLadders3,
        img: SiS3_StandardImage1,
        otherImgs: [SiS3_StandardImage1, SiS3_StandardImage2, SiS3_StandardImage3],
        schemas: [SiS3_SchemaImage],
        specifications: [
            { depthFrom: '1,13', depthTo: '1,4', ladderLength: '1,12', steps: 4, orderNumber: 'STB 1120' },
            { depthFrom: '1,41', depthTo: '1,68', ladderLength: '1,4', steps: 5, orderNumber: 'STB 1400' },
            { depthFrom: '1,69', depthTo: '1,96', ladderLength: '1,68', steps: 6, orderNumber: 'STB 1680' },
            { depthFrom: '1,97', depthTo: '2,24', ladderLength: '1,96', steps: 7, orderNumber: 'STB 1960' },
            { depthFrom: '2,25', depthTo: '2,52', ladderLength: '2,24', steps: 8, orderNumber: 'STB 2240' },
            { depthFrom: '2,53', depthTo: '2,8', ladderLength: '2,52', steps: 9, orderNumber: 'STB 2520' },
            { depthFrom: '2,81', depthTo: '3,08', ladderLength: '2,8', steps: 10, orderNumber: 'STB 2800' },
            { depthFrom: '3,09', depthTo: '3,36', ladderLength: '3,08', steps: 11, orderNumber: 'STB 3080' },
            { depthFrom: '3,37', depthTo: '3,64', ladderLength: '3,36', steps: 12, orderNumber: 'STB 3360' },
            { depthFrom: '3,65', depthTo: '3,92', ladderLength: '3,64', steps: 13, orderNumber: 'STB 3640' },
            { depthFrom: '3,93', depthTo: '4,2', ladderLength: '3,92', steps: 14, orderNumber: 'STB 3920' },
            { depthFrom: '4,21', depthTo: '4,48', ladderLength: '4,2', steps: 15, orderNumber: 'STB 4200' },
            { depthFrom: '4,49', depthTo: '4,76', ladderLength: '4,48', steps: 16, orderNumber: 'STB 4480' },
            { depthFrom: '4,77', depthTo: '5,04', ladderLength: '4,76', steps: 17, orderNumber: 'STB 4760' },
            { depthFrom: '5,05', depthTo: '5,32', ladderLength: '5,04', steps: 18, orderNumber: 'STB 5040' },
            { depthFrom: '5,33', depthTo: '5,6', ladderLength: '5,32', steps: 19, orderNumber: 'STB 5320' },
            { depthFrom: '5,61', depthTo: '5,88', ladderLength: '5,6', steps: 20, orderNumber: 'STB 5600' },
            { depthFrom: '5,89', depthTo: '6,16', ladderLength: '5,88', steps: 21, orderNumber: 'STB 5880' },
        ]
    },
    {
        id: 9,
        name: "Uprząż bezpieczeństwa S5c",
        description: "Uprząż bezpieczeństwa wyposażona jest w samorozwijający się pas bezpieczeństwa i haki zabezpieczające. Produkt certyfikowany i dopuszczony przez „TÜV SÜD PRODUCT SERVICE GmbH”, CE 0123 zgodnie z normą DIN EN 353-1. Odpowiedni w zastosowaniu z poręczą HUBER FSS.\t\t\t\t\n",
        img: S5C_StandardImage1,
        otherImgs: [S5C_StandardImage1, S5C_StandardImage2, S5C_StandardImage3],
        schemas: [],
        specifications: []
    },
    {
        id: 10,
        name: "Poręcz EH FSS / FSSD",
        description: "Poręcz zakładana na drabinę wykonana została ze stali nierdzewnej 1.4307 (AISI 304 L) z przeznaczeniem do drabin o szerokości 400 mm lub 500 mm.\n" +
            "Zgodność z normą DIN 19572.\n" +
            "Konstrukcja poddana obróbce w kąpieli kwaśnej i pasywowana.",
        img: EH_FFS_StandardImage1,
        otherImgs: [EH_FFS_StandardImage1, EH_FFS_StandardImage2],
        schemas: [EH_FFS_SchemaImage],
        specifications: []
    },
]

export const equipmentManholesProducts = [
    {
        id: 1,
        name: "Krata ochronna ASG",
        description: "Kratka ASG zgodna jest z przepisami DGUV 103-007, o kształcie prostokątnym, wykonana ze stali nierdzewnej 1.4307 (AISI 304 L), ma uniwersalne zastosowanie i możliwość montażu pod pokrywami włazów.\n" +
            "Krata wykonana z blachy perforowanej o nośności do 150 kg.\n" +
            "Zarówno ruszt, jak i wspornik są spawane łukowo oraz wytrawiane w kąpieli kwaśnej i pasywowane.\n" +
            "W komplecie materiał mocujący.",
        columns: equipmentManholesDefault,
        img: Krata_ASG_StandardImage1,
        otherImgs: [Krata_ASG_StandardImage1, Krata_ASG_StandardImage2],
        schemas: [Krata_ASG_SchemaImage1, Krata_ASG_SchemaImage2],
        specifications: [
            { model: 'Standard', size: '800 x 800', orderNumber: 'ASG 800x800 W400' },
            { model: 'Standard', size: '1000 x 1000', orderNumber: 'ASG 1000x1000 W600' },
        ]
    },
    {
        id: 2,
        name: "Krata ochronna ASG.P",
        description: "Kratka ASG.P zgodna jest z przepisami DGUV 103-007, o kształcie prostokątnym, wykonana ze stali nierdzewnej 1.4307 (AISI 304 L), ma uniwersalne zastosowanie i możliwość montażu pod pokrywami włazów.\n" +
            "Krata wykonana z profilu kątowego o nośności do 200 kg.\n" +
            "Zarówno ruszt, jak i wspornik są spawane łukowo oraz wytrawiane w kąpieli kwaśnej i pasywowane.\n" +
            "W komplecie materiał mocujący.",
        columns: equipmentManholesDefault,
        img: Krata_ASG_P_StandardImage1,
        otherImgs: [Krata_ASG_P_StandardImage1, Krata_ASG_P_StandardImage2],
        schemas: [Krata_ASG_P_SchemaImage1, Krata_ASG_P_SchemaImage2],
        specifications: [
            { model: 'Standard', size: '800 x 800', orderNumber: 'ASG.P 800x800 W400\n' },
            { model: 'Standard', size: '1000 x 1000', orderNumber: 'ASG.P 1000x1000 W600\n' },
        ]
    },
    {
        id: 3,
        name: "Zbiornik CT",
        description: "Zbiornik w kształcie prostokąta, wykonany ze stali nierdzewnej 1.4307 (AISI 304 L), o uniwersalnym zastosowaniu, do montażu pod włazy jako zabezpieczenie przed przedostawaniem się do wewnątrz szkodliwych substancji chemicznych, cieczy lub podobnych substancji.\n" +
            "Pokrywa wykonana jest z blachy nierdzewnej, z centralnie podwyższonym profilem, zdejmowana, z dwoma uchwytami.\n" +
            "Rama składa się z kątownika zaprojektowanego jako kanał odpływowy, przeznaczony do mocowania śrubowego.\n" +
            "W komplecie materiał mocujący. Zbiornik wytrawiany w kąpieli kwaśnej i pasywowany.",
        columns: equipmentManholesTank,
        img: Zbiornik_CT_StandardImage,
        otherImgs: [],
        schemas: [Zbiornik_CT_SchemaImage],
        specifications: [
            {model: 'Zbiornik CT', size: '800 x 800', orderNumber: 'CT G 800x800', internalDimensions: '550 x 550', externalDimensions: '795 x 795'},
            {model: 'Zbiornik CT', size: '1000 x 1000', orderNumber: 'CT G 1000x1000', internalDimensions: '750 x 750', externalDimensions: '995 x 995'},
        ]
    },
]

export const laddersProducts = [
    {
        id: 1,
        name: "Drabina SiS1",
        description: "Drabina posiada certyfikat konstrukcji i oznakowanie CE, zgodna z normą DIN EN 14396, wykonana jest ze stali nierdzewnej 1.4307 (AISI 304 L).\n" +
            "Uchwyty wykonane ze specjalnego profilu o dużej sztywności.\n" +
            "Szczeble wykonane z profilu U z powierzchnią perforowaną, antypoślizgowe.\n" +
            "Drabina spawana łukowo, poddana obróbce w kąpieli kwaśnej i pasywowana.\n" +
            "Typ A) Montaż pionowy\n" +
            "Zawiera mocowanie ścienne, wsporniki o regulowanej wysokości do kołkowania.\n" +
            "Typ B) Montaż do ścianypod kątem\n" +
            "Z haczykami na górnym końcu do zaczepienia o dostarczony zacisk, z uchwytami na dolnym końcu do przymocowania do ściany, z gumowymi zderzakami do ochrony ściany.\n" +
            "Typ C) Montaż do podłogi\n" +
            "Z haczykami na górnym końcu do zaczepienia o dostarczony zacisk, z gumowymi zderzakami na dolnym końcu.",
        columns: laddersColumn,
        img: SiS1_StandardImage1,
        otherImgs: [SiS1_StandardImage1, SiS1_StandardImage2],
        schemas: [SiS1_SchemaImage1, SiS1_SchemaImage2, SiS1_SchemaImage3],
        specifications: [
            { length: '0,61', steps: 3, width: '300 mm', verticalMount: 'SA3 610', angledWallMount: 'SB3 610', floorMount: 'SC3 610' },
            { length: '0,89', steps: 4, width: '300 mm', verticalMount: 'SA3 890', angledWallMount: 'SB3 890', floorMount: 'SC3 890' },
            { length: '1,17', steps: 5, width: '300 mm', verticalMount: 'SA3 1170', angledWallMount: 'SB3 1170', floorMount: 'SC3 1170' },
            { length: '1,45', steps: 6, width: '300 mm', verticalMount: 'SA3 1450', angledWallMount: 'SB3 1450', floorMount: 'SC3 1450' },
            { length: '1,73', steps: 7, width: '300 mm', verticalMount: 'SA3 1730', angledWallMount: 'SB3 1730', floorMount: 'SC3 1730' },
            { length: '2,01', steps: 8, width: '300 mm', verticalMount: 'SA3 2010', angledWallMount: 'SB3 2010', floorMount: 'SC3 2010' },
            { length: '2,29', steps: 9, width: '300 mm', verticalMount: 'SA3 2290', angledWallMount: 'SB3 2290', floorMount: 'SC3 2290' },
            { length: '2,57', steps: 10, width: '300 mm', verticalMount: 'SA3 2570', angledWallMount: 'SB3 2570', floorMount: 'SC3 2570' },
            { length: '2,85', steps: 11, width: '300 mm', verticalMount: 'SA3 2850', angledWallMount: 'SB3 2850', floorMount: 'SC3 2850' },
            { length: '3,13', steps: 12, width: '300 mm', verticalMount: 'SA3 3130', angledWallMount: 'SB3 3130', floorMount: 'SC3 3130' },
            { length: '3,41', steps: 13, width: '300 mm', verticalMount: 'SA3 3410', angledWallMount: 'SB3 3410', floorMount: 'SC3 3410' },
            { length: '3,69', steps: 14, width: '300 mm', verticalMount: 'SA3 3690', angledWallMount: 'SB3 3690', floorMount: 'SC3 3690' },
            { length: '3,97', steps: 15, width: '300 mm', verticalMount: 'SA3 3970 *', angledWallMount: 'SB3 3970 *', floorMount: 'SC3 3970 *' },
            { length: '4,25', steps: 16, width: '300 mm', verticalMount: 'SA3 4250 *', angledWallMount: 'SB3 4250 *', floorMount: 'SC3 4250 *' },
            { length: '4,53', steps: 17, width: '300 mm', verticalMount: 'SA3 4530 *', angledWallMount: 'SB3 4530 *', floorMount: 'SC3 4530 *' },
            { length: '4,81', steps: 18, width: '300 mm', verticalMount: 'SA3 4810 *', angledWallMount: 'SB3 4810 *', floorMount: 'SC3 4810 *' },
            { length: '0,61', steps: 3, width: '400 mm', verticalMount: 'SA4 610', angledWallMount: 'SB4 610', floorMount: 'SC4 610' },
            { length: '0,89', steps: 4, width: '400 mm', verticalMount: 'SA4 890', angledWallMount: 'SB4 890', floorMount: 'SC4 890' },
            { length: '1,17', steps: 5, width: '400 mm', verticalMount: 'SA4 1170', angledWallMount: 'SB4 1170', floorMount: 'SC4 1170' },
            { length: '1,45', steps: 6, width: '400 mm', verticalMount: 'SA4 1450', angledWallMount: 'SB4 1450', floorMount: 'SC4 1450' },
            { length: '1,73', steps: 7, width: '400 mm', verticalMount: 'SA4 1730', angledWallMount: 'SB4 1730', floorMount: 'SC4 1730' },
            { length: '2,01', steps: 8, width: '400 mm', verticalMount: 'SA4 2010', angledWallMount: 'SB4 2010', floorMount: 'SC4 2010' },
            { length: '2,29', steps: 9, width: '400 mm', verticalMount: 'SA4 2290', angledWallMount: 'SB4 2290', floorMount: 'SC4 2290' },
            { length: '2,57', steps: 10, width: '400 mm', verticalMount: 'SA4 2570', angledWallMount: 'SB4 2570', floorMount: 'SC4 2570' },
            { length: '2,85', steps: 11, width: '400 mm', verticalMount: 'SA4 2850', angledWallMount: 'SB4 2850', floorMount: 'SC4 2850' },
            { length: '3,13', steps: 12, width: '400 mm', verticalMount: 'SA4 3130', angledWallMount: 'SB4 3130', floorMount: 'SC4 3130' },
            { length: '3,41', steps: 13, width: '400 mm', verticalMount: 'SA4 3410', angledWallMount: 'SB4 3410', floorMount: 'SC4 3410' },
            { length: '3,69', steps: 14, width: '400 mm', verticalMount: 'SA4 3690', angledWallMount: 'SB4 3690', floorMount: 'SC4 3690' },
            { length: '3,97', steps: 15, width: '400 mm', verticalMount: 'SA4 3970', angledWallMount: 'SB4 3970', floorMount: 'SB4 3970' },
            { length: '4,25', steps: 16, width: '400 mm', verticalMount: 'SA4 4250', angledWallMount: 'SB4 4250', floorMount: 'SB4 4250' },
            { length: '4,53', steps: 17, width: '400 mm', verticalMount: 'SA4 4530', angledWallMount: 'SB4 4530', floorMount: 'SB4 4530' },
            { length: '4,81', steps: 18, width: '400 mm', verticalMount: 'SA4 4810', angledWallMount: 'SB4 4810', floorMount: 'SB4 4810' },
            { length: '0,61', steps: 3, width: '500 mm', verticalMount: 'SA5 610', angledWallMount: 'SB5 610', floorMount: 'SC5 610' },
            { length: '0,89', steps: 4, width: '500 mm', verticalMount: 'SA5 890', angledWallMount: 'SB5 890', floorMount: 'SC5 890' },
            { length: '1,17', steps: 5, width: '500 mm', verticalMount: 'SA5 1170', angledWallMount: 'SB5 1170', floorMount: 'SC5 1170' },
            { length: '1,45', steps: 6, width: '500 mm', verticalMount: 'SA5 1450', angledWallMount: 'SB5 1450', floorMount: 'SC5 1450' },
            { length: '1,73', steps: 7, width: '500 mm', verticalMount: 'SA5 1730', angledWallMount: 'SB5 1730', floorMount: 'SC5 1730' },
            { length: '2,01', steps: 8, width: '500 mm', verticalMount: 'SA5 2010', angledWallMount: 'SB5 2010', floorMount: 'SC5 2010' },
            { length: '2,29', steps: 9, width: '500 mm', verticalMount: 'SA5 2290', angledWallMount: 'SB5 2290', floorMount: 'SC5 2290' },
            { length: '2,57', steps: 10, width: '500 mm', verticalMount: 'SA5 2570', angledWallMount: 'SB5 2570', floorMount: 'SC5 2570' },
            { length: '2,85', steps: 11, width: '500 mm', verticalMount: 'SA5 2850', angledWallMount: 'SB5 2850', floorMount: 'SC5 2850' },
            { length: '3,13', steps: 12, width: '500 mm', verticalMount: 'SA5 3130', angledWallMount: 'SB5 3130', floorMount: 'SC5 3130' },
            { length: '3,41', steps: 13, width: '500 mm', verticalMount: 'SA5 3410', angledWallMount: 'SB5 3410', floorMount: 'SC5 3410' },
            { length: '3,69', steps: 14, width: '500 mm', verticalMount: 'SA5 3690', angledWallMount: 'SB5 3690', floorMount: 'SC5 3690' },
            { length: '3,97', steps: 15, width: '500 mm', verticalMount: 'SA5 3970', angledWallMount: 'SB5 3970', floorMount: 'SC5 3970' },
            { length: '4,25', steps: 16, width: '500 mm', verticalMount: 'SA5 4250', angledWallMount: 'SB5 4250', floorMount: 'SC5 4250' },
            { length: '4,53', steps: 17, width: '500 mm', verticalMount: 'SA5 4530', angledWallMount: 'SB5 4530', floorMount: 'SC5 4530' },
            { length: '4,81', steps: 18, width: '500 mm', verticalMount: 'SA5 4810', angledWallMount: 'SB5 4810', floorMount: 'SC5 4810' },
        ]
    },
]