import React from 'react';
import CookieConsent from 'react-cookie-consent';
import './CookieBanner.css'

const CookieBanner: React.FC = () => {
    return (
        <CookieConsent
            location="bottom"
            buttonText="Zgadzam się"
            cookieName="myCookieConsent"
            expires={150}
        >
            <div className="cookie-message">
                Witryna wykorzystuje pliki cookie, aby zapewnić jak najlepsze wrażenia użytkownika. Obejmują one pliki
                cookie niezbędne do działania witryny, pliki cookie wykorzystywane do anonimowych celów statystycznych
                oraz pliki cookie potrzebne do integracji treści zewnętrznych. Użytkownik może zdecydować, na które
                kategorie plików cookie chce zezwolić. Należy pamiętać, że ustawienia mogą ograniczyć funkcje dostępne
                na tej stronie.
            </div>
            <div className="cookie-message-privacyPolicy">
                Więcej informacji można znaleźć w naszym oświadczeniu o ochronie danych.
                <a className="cookie-message-privacyPolicy-span"
                   href="https://www.huber.com.pl/pl/stopka-redakcyjna/polityka-prywatnosci.html">Polityka
                    prywatności</a>
            </div>
        </CookieConsent>
    );
};

export default CookieBanner;
