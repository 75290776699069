import {CategoryItem} from "../../../../components/category-item/CategoryItem";
import '../../Products.css';
import {equipmentManholesProducts} from "../../../../Data";
import {useProductNavigation} from "../../../../utils/utils";
import {Banner} from "../../../../components/banners/banner/Banner";
import React from "react";

export default function EquipmentManholesList() {
    const { handleProductClick } = useProductNavigation();

    return (
       <div>
           <Banner title={"WYPOSAŻENIE"}/>
           <div className="equipment-main-container">
               {equipmentManholesProducts.map((product) => (
                   <CategoryItem
                       key={product.id}
                       id={product.id}
                       img={product.img}
                       name={product.name}
                       targetUrl={`/produkty/wyposazenie/wlazy/${product.id}`}
                       onClick={() => handleProductClick(`/produkty/wyposazenie/wlazy/${product.id}`)}
                   />
               ))}
           </div>
       </div>
    )
}
