import React from "react";
import {useParams} from "react-router-dom";
import ProductItem from "../../../components/product-item/ProductItem";
import {technicalDoorsProducts} from "../../../Data";
import Accordion from "../../../components/accordion/Accordion";

import GenericTable from "../../../components/table/GenericTable";
import SchemaImageList from "../../../components/schema-image/SchemaImagesList";
import {Banner} from "../../../components/banners/banner/Banner";

import '../Products.css';

export default function DoorDetails() {
    const {id} = useParams();
    const selectedDoor = technicalDoorsProducts.find((m) => m.id.toString() === id);

    if (!selectedDoor) {
        return <div className="not-found-product">Nie znaleziono drzwi</div>;
    }

    const shouldRenderTable = selectedDoor.specifications && selectedDoor.specifications.length > 1;

    return (
        <div>
            <Banner title={"Drzwi techniczne"}/>
            <ProductItem item={selectedDoor}/>
            <div className="container center-container">
                <div className="accordions-section">
                    {shouldRenderTable && (
                        <Accordion
                            title="Tabela wymiarów"
                            content={<GenericTable columns={selectedDoor.columns as any[]}
                                                   data={selectedDoor.specifications}/>}
                        />
                    )}
                </div>
                <div className="accordions-section">
                    <Accordion
                        title="Rysunek techniczny"
                        content={
                            <SchemaImageList images={selectedDoor.schemas}/>
                        }
                    />
                </div>
            </div>
        </div>
    );
};
