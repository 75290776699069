import React, {useState} from 'react';
import {ErrorProps, FormProps} from './FormInterface';
import {
    Box,
    Container,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    styled,
    TextField
} from '@mui/material';
import {validateField, validateForm} from './validation';

interface ErrorTextProps {
    tag: keyof JSX.IntrinsicElements;
    color: string;
    text: string;
}

const ErrorText: React.FC<ErrorTextProps> = (props) => {
    const Text = styled(props.tag)`
      text-align: center;
      color: ${props.color};
    `;
    return <Text>{props.text}</Text>;
};

const contactTopics = [
    {value: 'WŁAZ SD1 typ prostokątny', label: 'WŁAZ SD1 typ prostokątny'},
    {value: 'WŁAZ SD2 typ okrągły', label: 'WŁAZ SD2 typ okrągły'},
    {value: 'WŁAZ SD1.P typ prostokątny', label: 'WŁAZ SD1.P typ prostokątny'},
    {value: 'WŁAZ SD3-RC3 typ prostokątny', label: 'WŁAZ SD3-RC3 typ prostokątny'},
    {value: 'Właz SD4-RC3 typ okrągły', label: "Właz SD4-RC3 typ okrągły"},
    {value: 'WŁAZ SD50 typ prostokątny', label: 'WŁAZ SD50 typ prostokątny'},
    {value: 'WŁAZ SD5 wodoszczelny typ prostokątny', label: 'WŁAZ SD5 wodoszczelny typ prostokątny'},
    {value: 'Właz SD6 wodoszczelny, typ okrągły', label: 'Właz SD6 wodoszczelny, typ okrągły'},
    {
        value: 'Właz SD7 o podwyższonej odporności, typ prostokątny',
        label: 'Właz SD7 o podwyższonej odporności, typ prostokątny'
    },
    {
        value: 'Właz SD7.R o podwyższonej odporności, typ okrągły',
        label: 'Właz SD7.R o podwyższonej odporności, typ okrągły'
    },
    {
        value: 'Właz SD7-RC4 o podwyższonej odporności, typ prostokątny',
        label: 'Właz SD7-RC4 o podwyższonej odporności, typ prostokątny'
    },
    {value: 'Właz SD11 o lekkiej konstrukcji', label: 'Właz SD11 o lekkiej konstrukcji'},
    {value: 'Właz SD12 przesuwny', label: 'Właz SD12 przesuwny'},
    {value: 'Drzwi techniczne TT1.1', label: 'Drzwi techniczne TT1.1'},
    {value: 'Drzwi techniczne TT1.1 W', label: 'Drzwi techniczne TT1.1 W'},
    {value: 'Drzwi techniczne TT1.2', label: 'Drzwi techniczne TT1.2'},
    {value: 'Drzwi techniczne antywłamaniowe TT2.1/RC3', label: 'Drzwi techniczne antywłamaniowe TT2.1/RC3'},
    {value: 'Drzwi techniczne antywłamaniowe TT2.2/RC3', label: 'Drzwi techniczne antywłamaniowe TT2.2/RC3'},
    {value: 'Drzwi techniczne antywłamaniowe TT2.1/RC4', label: 'Drzwi techniczne antywłamaniowe TT2.1/RC4'},
    {value: 'Drzwi techniczne antywłamaniowe TT2.2/RC4', label: 'Drzwi techniczne antywłamaniowe TT2.2/RC4'},
    {value: 'Drzwi dedykowane dla chlorowni TT5', label: 'Drzwi dedykowane dla chlorowni TT5'},
    {value: 'Drzwi zewnętrzne przeciwpowodziowe TT6', label: 'Drzwi zewnętrzne przeciwpowodziowe TT6'},
    {value: 'Drzwi  TT7 z dźwigniami ryglującymi', label: 'Drzwi  TT7 z dźwigniami ryglującymi'},
    {value: 'Drzwi ciśnieniowe TT7.Z z zamkiem centralnym', label: 'Drzwi ciśnieniowe TT7.Z z zamkiem centralnym'},
    {value: 'Drzwi ciśnieniowe TT7.30 z zamkiem centralnym', label: 'Drzwi ciśnieniowe TT7.30 z zamkiem centralnym'},
    {value: 'Drzwi ciśnieniowe TT7.R z zamkami śrubowymi', label: 'Drzwi ciśnieniowe TT7.R z zamkami śrubowymi'},
    {value: 'Składane przedłużenie drabiny EH K', label: 'Składane przedłużenie drabiny EH K'},
    {value: 'Składane przedłużenie drabiny z uchwytem EH KV', label: 'Składane przedłużenie drabiny z uchwytem EH KV'},
    {value: 'Zakładane przedłużenie drabiny EH S', label: 'Zakładane przedłużenie drabiny EH S'},
    {value: 'Poręcz do drabiny EH VSD', label: 'Poręcz do drabiny EH VSD'},
    {value: 'Uchwyt do drabiny EH VS', label: 'Uchwyt do drabiny EH VS'},
    {value: 'Poręcz wejściowa SiS5 HB', label: 'Poręcz wejściowa SiS5 HB'},
    {value: 'Drabina z systemem zabezpieczenia SiS2', label: 'Drabina z systemem zabezpieczenia SiS2'},
    {value: 'Drabina z systemem zabezpieczenia SiS3', label: 'Drabina z systemem zabezpieczenia SiS3'},
    {value: 'Uprząż bezpieczeństwa S5c', label: 'Uprząż bezpieczeństwa S5c'},
    {value: 'Poręcz EH FSS / FSSD', label: 'Poręcz EH FSS / FSSD'},
    {value: 'Krata ochronna ASG', label: 'Krata ochronna ASG'},
    {value: 'Krata ochronna ASG.P', label: 'Krata ochronna ASG.P'},
    {value: 'Zbiornik CT', label: 'Zbiornik CT'},
    {value: 'Drabina SiS1', label: 'Drabina SiS1'},
];

export default function ContactForm() {
    const [formValues, setFormValues] = useState<FormProps>({
        name: '',
        topic: '',
        company: '',
        email: '',
        phone: '',
        message: '',
    })

    const [formErrors, setFormErrors] = useState<ErrorProps>({
        message: '',
        topic: '',
        email: '',
        phone: '',
    });

    const handleSelectChange = (event: SelectChangeEvent<string>) => {
        const {value} = event.target;
        setFormValues((prev) => ({...prev, topic: value}));
        setFormErrors((prev) => ({...prev, topic: validateField('topic', value)}));
    };

    const handleChange = (
        event: React.ChangeEvent<HTMLInputElement | { name?: string; value: unknown }>
    ) => {
        if ('name' in event.target) {
            const {name, value} = event.target as { name: keyof FormProps; value: string };
            setFormValues((prev) => ({...prev, [name]: value}));
            setFormErrors((prev) => ({...prev, [name]: validateField(name, value)}));
        }
    }

    const handleMailtoClick = (event: { preventDefault: () => void; }) => {
        const isValid = checkIsValid();
        if (!isValid) {
            event.preventDefault();
            return;
        }

        const {name, company, email, phone, topic, message} = formValues;

        const subject = encodeURIComponent(topic);

        const body = `Imię i nazwisko: ${name}\nFirma: ${company}\nEmail: ${email}\nTelefon: ${phone}\nTemat: ${topic}\nWiadomość: ${message}`;
        window.location.href = `mailto:huber@huber.com.pl?subject=${subject}&body=${encodeURIComponent(body)}`;
    };

    const checkIsValid = () => {
        const newErrors = validateForm(formValues);
        setFormErrors(newErrors as ErrorProps);
        return Object.values(newErrors).every(x => x === '');
    }

    return (
        <div className="contact-box-container">
            <Container maxWidth="sm">
                <p className="contact-box-title">Formularz kontaktowy</p>
                <Box component="form" sx={{mt: 2, marginLeft: '20px', marginRight: '20px'}}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <InputLabel className="contact-box-labels">Imię i nazwisko</InputLabel>
                            <TextField
                                name="name"
                                type="text"
                                placeholder="Wpisz imię i nazwisko"
                                className="contact-box-input"
                                variant="outlined"
                                value={formValues.name}
                                onChange={handleChange}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <InputLabel className="contact-box-labels">Firma</InputLabel>
                            <TextField
                                name="company"
                                placeholder="Wpisz nazwę firmy"
                                className="contact-box-input"
                                variant="outlined"
                                value={formValues.company}
                                onChange={handleChange}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <InputLabel className="contact-box-labels">*Telefon</InputLabel>
                            <TextField
                                name="phone"
                                type="tel"
                                placeholder="Wpisz numer telefonu"
                                className="contact-box-input"
                                variant="outlined"
                                error={!!formErrors.phone}
                                value={formValues.phone}
                                onChange={handleChange}
                                fullWidth
                            />
                            {formErrors.phone &&
                                <ErrorText
                                    tag="span"
                                    color="red"
                                    text={formErrors.phone}
                                />}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <InputLabel className="contact-box-labels">*E-mail</InputLabel>
                            <TextField
                                name="email"
                                placeholder="Wpisz e-mail"
                                className="contact-box-input"
                                variant="outlined"
                                value={formValues.email}
                                error={!!formErrors.email}
                                onChange={handleChange}
                                fullWidth
                            />
                            {formErrors.email &&
                                <ErrorText
                                    tag="span"
                                    color="red"
                                    text={formErrors.email}
                                />}
                        </Grid>
                        <Grid item xs={12}>
                            <InputLabel className="contact-box-labels">*Temat</InputLabel>
                            <FormControl fullWidth variant="outlined">
                                <Select
                                    labelId="topic-label"
                                    className={`contact-box-select-input ${formValues.topic === '' ? 'placeholder-selected' : ''}`}
                                    id="topic"
                                    name="topic"
                                    error={!!formErrors.topic}
                                    value={formValues.topic}
                                    onChange={handleSelectChange}
                                    displayEmpty
                                >
                                    <MenuItem value="" disabled>
                                        Wybierz temat
                                    </MenuItem>
                                    {contactTopics.map((topic) => (
                                        <MenuItem key={topic.value} value={topic.value} className="menu-item">
                                            {topic.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            {formErrors.topic &&
                                <ErrorText
                                    tag="span"
                                    color="red"
                                    text={formErrors.topic}
                                />}
                        </Grid>
                        <Grid item xs={12}>
                            <InputLabel className="contact-box-labels">*Wiadomość</InputLabel>
                            <TextField
                                name="message"
                                className="contact-box-textarea-input"
                                placeholder="Twoja wiadomość"
                                variant="outlined"
                                value={formValues.message}
                                error={!!formErrors.message}
                                onChange={handleChange}
                                multiline
                                rows={5}
                                fullWidth
                            />
                            {formErrors.message &&
                                <ErrorText
                                    tag="span"
                                    color="red"
                                    text={formErrors.message}
                                />}
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <p className="contact-box-additional-info">Pola oznaczone gwiazdką są wymagane</p>
                        <Box display="flex" justifyContent="center">
                            <a onClick={handleMailtoClick}>
                                <button className="contact-send-button">Wyślij wiadomość</button>
                            </a>
                        </Box>
                    </Grid>
                </Box>
            </Container>
        </div>
    );
}
