import React, {useEffect, useRef, useState} from "react";
import {FaBars, FaTimes} from "react-icons/fa";
import "./Header.css";
import huberLogo from '../../src/assets/logos/HUBER_logo.jpg';
import NavItem from "./NavItem";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleDown, faAngleUp} from "@fortawesome/free-solid-svg-icons";
import {Link, useLocation} from "react-router-dom";

const dropdownItemsData = [
    { text: "Włazy", href: "/produkty/wlazy" },
    { text: "Drabiny", href: "/produkty/drabiny" },
    { text: "Drzwi techniczne", href: "/produkty/drzwi" },
    { text: "Wyposażenie", href: "/produkty/wyposazenie" },
];

function Header() {
    const navRef = useRef<HTMLDivElement | null>(null);
    const [isDropdownVisible, setDropdownVisible] = useState(false);
    const [isProductsActive, setProductsActive] = useState(false);
    const [isTouchDevice, setIsTouchDevice] = useState(false);

    const location = useLocation();
    const currentRoute = location.pathname;

    const toggleNavbar = () => {
        if (navRef.current) {
            navRef.current.classList.toggle("responsive_nav");
        }
    };

    useEffect(() => {
        setIsTouchDevice(window.matchMedia("(hover: none)").matches);
        const checkIfClickedOutside = (e: any) => {
            if (isDropdownVisible && navRef.current && !navRef.current.contains(e.target)) {
                setDropdownVisible(false);
            }
        };

        document.addEventListener("mousedown", checkIfClickedOutside);

        return () => {
            document.removeEventListener("mousedown", checkIfClickedOutside);
        };
    }, [ isDropdownVisible]);

    const handleMouseEnter = () => {
        if (!("ontouchstart" in document.documentElement)) {
            setDropdownVisible(true);
        }
    };

    const handleMouseLeave = () => {
        setDropdownVisible(false);
    };

    return (
      <div className="header-container">
          <div className="logo-container">
              <Link to="/">
                  <img src={huberLogo} alt="Huber logo" className="huber-logo-img"/>
              </Link>
          </div>
          <nav className="nav-container" ref={navRef}>
              <NavItem href={"/"} text={"Strona Główna"} active={currentRoute === "/"}/>
              {
                  isTouchDevice
                    ?
                    <div className={'mobile-container'} onMouseLeave={handleMouseLeave}>
                        <div className="nav-link nav-dropdown-btn nav-container-link">
                            <Link
                              to="/produkty"
                              className={`${isProductsActive ? 'active' : ''}`}
                            >
                                Oferta
                            </Link>
                            <div onClick={() => setDropdownVisible(!isDropdownVisible)} className="dropdown-mobile-toggle">
                                {isDropdownVisible ? <FontAwesomeIcon
                                    icon={faAngleUp}
                                    className="angleDown-icon"
                                /> : <FontAwesomeIcon
                                    icon={faAngleDown}
                                    className="angleDown-icon"
                                />}
                            </div>
                        </div>
                        {isDropdownVisible && (
                          <div className="dropdown-mobile-container">
                              {dropdownItemsData.map((item, index) => (
                                <div key={`DropdownMobile_${index}`} className="dropdown-mobile-item">
                                    <Link to={item.href} className={`${currentRoute  === item.href ? 'active' : ''}`}>
                                        {item.text}
                                    </Link>
                                </div>
                              ))}
                          </div>
                        )}
                    </div>
                    :
                    (<div className="dropdown-container" onMouseLeave={handleMouseLeave}>
                        <Link
                          to="/produkty"
                          className={`nav-link nav-dropdown-btn nav-container-link ${isProductsActive ? 'active' : ''}`}
                          onMouseEnter={handleMouseEnter}
                        >
                            Oferta
                            <FontAwesomeIcon
                              icon={faAngleDown}
                              className="angleDown-icon"
                            />
                        </Link>
                        {isDropdownVisible && (
                          <div className="dropdown-menu">
                              {dropdownItemsData.map((item, index) => (
                                  <div key={`DropdownDefaultMenu_${index}`} className="dropdown-default-item">
                                      <Link to={item.href} className={`${currentRoute  === item.href ? 'active' : ''}`}>
                                          {item.text}
                                      </Link>
                                  </div>
                              ))}
                          </div>
                        )}
                    </div>)
              }
              <NavItem href={"/kontakt"} text={"Kontakt"} active={currentRoute === "/kontakt"}/>
              <button className="nav-btn nav-close-btn" onClick={toggleNavbar}>
                  <FaTimes/>
              </button>
          </nav>
          <button className="nav-btn" onClick={toggleNavbar}>
              <FaBars/>
          </button>
      </div>
    );
}

export default Header;
