import React, { useEffect } from 'react';
import './ScrollUp.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowUp} from "@fortawesome/free-solid-svg-icons";

const ScrollUp: React.FC = () => {
    useEffect(() => {
        const handleScroll = () => {
            const scrollUp = document.querySelector('.scrollup') as HTMLElement;
            // when the scroll is higher than 560 viewport height,add the show-scroll class to a tag with the scroll
            if (window.scrollY >= 560) scrollUp.classList.add('show-scroll');
            else scrollUp.classList.remove('show-scroll');
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <a href="#" className="scrollup">
            <FontAwesomeIcon
                icon={faArrowUp}
                className="scrollup-icon"
            />
        </a>
    );
};

export default ScrollUp
