import { useNavigate } from "react-router-dom";

export const useProductNavigation = () => {
    const navigate = useNavigate();

    const handleProductClick = (targetUrl: string) => {
        navigate(targetUrl);
    };

    return {
        handleProductClick,
    };
};
