import '../Products.css';
import {CategoryItem} from "../../../components/category-item/CategoryItem";
import {manholesProducts} from "../../../Data";
import {useProductNavigation} from "../../../utils/utils";
import {Banner} from "../../../components/banners/banner/Banner";

export default function ManholesList() {
    const { handleProductClick } = useProductNavigation();

    return (
        <div>
            <Banner title={"WŁAZY"}/>
            <div className="manholes-main-container">
                {manholesProducts.map((product) => (
                    <CategoryItem
                        key={product.id}
                        id={product.id}
                        img={product.img}
                        name={product.name}
                        targetUrl={`/produkty/wlazy/${product.id}`}
                        onClick={() => handleProductClick(`/produkty/wlazy/${product.id}`)}
                    />
                ))}
            </div>
        </div>
    );
};

