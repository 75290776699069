import './Products.css'
import {categoryData} from "../../Data";
import {Banner} from "../../components/banners/banner/Banner";
import React from "react";

export default function Products() {
    return (
        <div>
            <Banner title={"OFERTA"}/>
            <div className="products-main-container">
                <div className="container product-desc-container" >
                    <div style={{alignItems: 'left', justifyContent: 'left'}}>
                        <p className="products-content-description">Poza urządzeniami do mechanicznego oczyszczania ścieków, obróbki skratek, separacji i obróbki piasku, przeróbki osadu, membranowego oczyszczania ścieków, HUBER oferuje szereg produktów dotyczących wyposażenia ze stali nierdzewnej, w tym:</p>
                        <ul className="aboutUs-ul" style={{listStylePosition: 'inside', textAlign: 'left'}}>
                            <li>włazy</li>
                            <li>drabiny</li>
                            <li>drzwi techniczne</li>
                            <li>wyposażenie włazów oraz drabin</li>
                        </ul>
                        <p className="products-content-description">Nasze produkty odpowiadają najwyższym wymaganiom pod względem:</p>
                        <ul className="aboutUs-ul" style={{listStylePosition: 'inside', textAlign: 'left'}}>
                            <li>trwałości materiału</li>
                            <li>zabezpieczenia przed korozją poprzez wytrawianie w kąpieli kwaśnej</li>
                            <li>pewności rozwiązania projektowego</li>
                            <li>bezpieczeństwa dla ludzi i środowiska przez utrzymanie warunków higienicznych na najwyższym poziomie.</li>
                        </ul>
                    </div>
                </div>
                <div>
                    <div className="products-flex-container">
                        {categoryData.map((product, index) => (
                            <div key={`ProductsContainer_${index}`} className="product-category-item">
                                <div className="product-image-container">
                                    <img className="product-image" src={product.image} alt={product.title}></img>
                                </div>
                                <div className="product-title-container">
                                    <h3 className="product-title">{product.title}</h3>
                                </div>
                                <div className="product-info-section">
                                    <a href={product.url} className="more-info-link">Zobacz więcej</a>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
};
