import React from "react";
import {useParams} from "react-router-dom";
import ProductItem from "../../../components/product-item/ProductItem";
import {laddersProducts} from "../../../Data";
import Accordion from "../../../components/accordion/Accordion";
import GenericTable from "../../../components/table/GenericTable";
import SchemaImageList from "../../../components/schema-image/SchemaImagesList";
import {Banner} from "../../../components/banners/banner/Banner";

import '../Products.css';

export default function LadderDetails() {
    const {id} = useParams();
    const selectedLadder = laddersProducts.find((m) => m.id.toString() === id);

    if (!selectedLadder) {
        return <div className="not-found-product">Nie znaleziono drabiny.</div>;
    }

    const shouldRenderTable = selectedLadder.specifications && selectedLadder.specifications.length > 1;

    return (
        <div>
            <Banner title={"DRABINY"}/>
            <ProductItem item={selectedLadder} />
            <div className="container center-container">
                <div className="accordions-section">
                    {shouldRenderTable && (
                        <Accordion
                            title="Tabela wymiarów"
                            content={<GenericTable columns={selectedLadder.columns as any[]} data={selectedLadder.specifications}/>}
                        />
                    )}
                </div>
                <div className="accordions-section">
                    <Accordion
                        title="Rysunek techniczny"
                        content={
                            <SchemaImageList images={selectedLadder.schemas} />
                        }
                    />
                </div>
            </div>
        </div>
    );
};

