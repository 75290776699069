import {ErrorProps, FormProps} from './FormInterface';

export const isValidEmail = (email: string) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

export const validateField = (name: keyof FormProps, value: string): string => {
  switch (name) {
    case 'message':
      if (!value) return 'To pole jest wymagane!';
      else if (value.length < 10) return 'Wiadomość nie może być krótsza niż 10 znaków!';
      else if (value.length > 300) return 'Wiadomość nie może być dłuższa niż 300 znaków!';
      break;
    case 'topic':
      if (!value) return 'To pole jest wymagane!';
      break;
    case 'phone':
      if (!value) return 'To pole jest wymagane!';
      else if (!/^[0-9]+$/.test(value)) return 'To pole musi zawierać tylko liczby.';
      else if (value.length > 12) return 'To pole nie może zawierać więcej niż 12 cyfr!';
      break;
    case 'email':
      if (!value) {
        return 'To pole jest wymagane!';
      } else if (!isValidEmail(value)) {
        return 'Adres email jest niepoprawny!';
      }
      break;
    default:
      return '';
  }
  return '';
};

export const validateForm = (formValues: FormProps): ErrorProps => {
  let errors: ErrorProps = { topic: '' , message: '', email: '', phone: '' };

  Object.keys(formValues).forEach((key) => {
    const value = formValues[key as keyof FormProps];
    const valueAsString = value === null || value === undefined ? '' : value.toString();
    errors[key as keyof ErrorProps] = validateField(key as keyof FormProps, valueAsString) as string;
  });


  return errors;
};
