import React, {ReactElement, JSXElementConstructor, ReactNode, ReactPortal} from "react";

import Footer from "./footer/Footer";
import Header from "./header/Header";


function Layout(props: { children: string | number | boolean | ReactElement<any, string | JSXElementConstructor<any>> | Iterable<ReactNode> | ReactPortal | null | undefined; }) {

    return (
        <div>
            <Header/>
            <main>{props.children}</main>
            <Footer/>
        </div>
    )
}

export default Layout;