import React, {ReactNode, useState} from 'react';
import './Accordion.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import {faPlus} from "@fortawesome/free-solid-svg-icons";
import {faMinus} from "@fortawesome/free-solid-svg-icons/faMinus";

interface CustomAccordionProps {
    title: string;
    content: ReactNode;
}

const Accordion: React.FC<CustomAccordionProps> = ({ title, content }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleAccordion = () => {
        setIsExpanded((prev) => !prev);
    };

    return (
        <div className={`custom-accordion ${isExpanded ? 'expanded' : ''}`}>
            <div className="accordion-section-container">
                <p className="accordion-section-header">
                    {title}
                </p>
                <p className="plus-section" onClick={toggleAccordion}>
                    <FontAwesomeIcon icon={isExpanded ? faMinus : faPlus} className="plus-icon"/>
                </p>
                {isExpanded && <div className="accordion-section-content">{content}</div>}
            </div>
        </div>
    );
};

export default Accordion;