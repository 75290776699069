import ContactForm from './ContactForm';
import ContactInfo from "./ContactInfo";
import './Contact.css';
import {Banner} from "../../components/banners/banner/Banner";

export default function Contact() {
    return (
        <div>
            <Banner title={"KONTAKT"}/>
            <div className="contact-main-container">
                <ContactForm/>
                <ContactInfo/>
            </div>
        </div>
    )
};
