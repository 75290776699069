import {CategoryItem} from "../../../../components/category-item/CategoryItem";
import {equipmentLaddersProducts} from "../../../../Data";
import {useProductNavigation} from "../../../../utils/utils";
import {Banner} from "../../../../components/banners/banner/Banner";
import React from "react";

export default function EquipmentLaddersList() {
    const { handleProductClick } = useProductNavigation();

    return (
        <div>
            <Banner title={"WYPOSAŻENIE"}/>
            <div className="equipment-main-container">
                {equipmentLaddersProducts.map((product) => (
                    <CategoryItem
                        key={product.id}
                        id={product.id}
                        img={product.img}
                        name={product.name}
                        targetUrl={`/produkty/wyposazenie/drabiny/${product.id}`}
                        onClick={() => handleProductClick(`/produkty/wyposazenie/drabiny/${product.id}`)}
                    />
                ))}
            </div>
        </div>
    )
}
