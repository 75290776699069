import React, { CSSProperties } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import {useMediaQuery} from "@mui/material";

interface LeafletMapProps {
    zIndex?: number;
    position?: CSSProperties['position'];
}

const LeafletMap: React.FC<LeafletMapProps> = ({ zIndex, position }) => {
    const smallMobileQuery = useMediaQuery('(max-width: 300px)');
    const mobileQuery = useMediaQuery('(min-width: 300px) and (max-width: 768px)');
    const tabletQuery = useMediaQuery('(min-width: 768px) and (max-width: 1024px)');
    const desktopQuery = useMediaQuery('(min-width: 1025px)');

    const mapStyle: CSSProperties = {
        height: '200px',
        width: '400px',
        zIndex: zIndex || 0,
        position: position || 'relative',
        borderRadius: '16px',
        background: 'lightgray 50%/cover no-repeat'
    };

    if (smallMobileQuery) {
        mapStyle.height = '200px';
        mapStyle.width = '200px';
    } else if (mobileQuery) {
        mapStyle.height = '200px';
        mapStyle.width = '300px';
    } else if (tabletQuery) {
        mapStyle.height = '200px';
        mapStyle.width = '400px';
    } else if (desktopQuery) {
        mapStyle.height = '200px';
        mapStyle.width = '400px';
    }

    return (
        <div>
            <MapContainer center={[52.18513, 20.90551]} zoom={17} style={mapStyle}>
                <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                />
                <Marker position={[52.18513, 20.90551]}>
                    <Popup>
                        <div>
                            <b>HUBER Technology Polska</b>
                        </div>
                        <div>
                            ul. Ryżowa 51
                        </div>
                        <div>
                            02-495 Warszawa
                        </div>
                    </Popup>
                </Marker>
            </MapContainer>
        </div>
    );
};

export default LeafletMap;
