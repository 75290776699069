import '../Products.css';

import {CategoryItem} from "../../../components/category-item/CategoryItem";
import {laddersProducts} from "../../../Data";
import {useProductNavigation} from "../../../utils/utils";
import {Banner} from "../../../components/banners/banner/Banner";
import React from "react";

export default function LaddersList(){
    const { handleProductClick } = useProductNavigation();

    return(
        <div>
            <Banner title={"DRABINY"}/>
            <div className="ladders-main-container">
                {laddersProducts.map((product) => (
                    <CategoryItem
                        key={product.id}
                        id={product.id}
                        img={product.img}
                        name={product.name}
                        targetUrl={`/produkty/drabiny/${product.id}`}
                        onClick={() => handleProductClick(`/produkty/drabiny/${product.id}`)}
                    />
                ))}
            </div>
        </div>
    )
};
