import {useState} from "react";
import "./ProductItem.css";
import {Link} from "react-router-dom";

export interface Item {
    id: number;
    name: string;
    description: string;
    img: any,
    otherImgs: any[],
    schemas: any[],
}

export interface ProductItemProps {
    item: Item;
}

const ProductItem: React.FC<ProductItemProps> = ({item}) => {
    const [image, setImage] = useState(item.img);

    const changeImage = (e: React.MouseEvent<HTMLImageElement>) => {
        const newImage = e.currentTarget.src;
        setImage(newImage);
    };

    return (
        <div className="product-page-container">
            <div className="container">
                <div>
                    <div className="product-display-container">
                        <div className="center-container" style={{width: "50%", float: "left"}}>
                            <div className="product-big-img">
                                <img src={image} alt="Product"/>
                            </div>
                            {item.otherImgs.length > 0 && (

                                <div className="product-small-images">
                                    {item.otherImgs.map((img, index) => (
                                        <img
                                            key={index}
                                            onMouseOver={changeImage}
                                            src={img}
                                            alt={`Small box ${index + 1}`}
                                        />
                                    ))}
                                </div>
                            )}
                        </div>
                        <div className="product-description-container" style={{width: "50%", float: "left"}}>
                            <p className="product-header">{item.name}</p>
                            <p className="product-description">{item.description}</p>
                            <Link className="custom-link" to="/kontakt">
                                <button className="ask-for-product-button">Zapytaj o produkt</button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ProductItem;