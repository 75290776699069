import {Route, Routes} from "react-router-dom";
import Home from "../main/home/Home";
import Contact from "../main/contact/Contact";
import LaddersList from "../main/products/ladders/LaddersList";
import DoorsList from "../main/products/doors/DoorsList";
import NoPage from "../main/nopage/NoPage";
import ManholesList from "../main/products/manholes/ManholesList";
import EquipmentCategories from "../main/products/equipment/EquipmentCategories";
import EquipmentManholesList from "../main/products/equipment/manhole/EquipmentManholesList";
import EquipmentLaddersList from "../main/products/equipment/ladders/EquipmentLaddersList";
import Products from "../main/products/Products";
import Manholes from "../main/products/manholes/ManholeDetails";
import DoorDetails from "../main/products/doors/DoorDetails";
import EquipmentLadderDetails from "../main/products/equipment/ladders/EquipmentLadderDetails";
import EquipmentManholeDetails from "../main/products/equipment/manhole/EquipmentManholeDetails";
import LadderDetails from "../main/products/ladders/LadderDetails";

export const AppRouter = () => {
    return (
        <Routes>
            <Route path="/" element={<Home/>}/>
            <Route path="/kontakt" element={<Contact/>}/>
            <Route path="/produkty" element={<Products/>}/>
            <Route path="/produkty/wlazy" element={<ManholesList/>}/>
            <Route path="/produkty/wlazy/:id" element={<Manholes/>}/>
            <Route path="/produkty/drabiny" element={<LaddersList/>}/>
            <Route path="/produkty/drabiny/:id" element={<LadderDetails/>}/>
            <Route path="/produkty/drzwi" element={<DoorsList/>}/>
            <Route path="/produkty/drzwi/:id" element={<DoorDetails/>}/>
            <Route path="/produkty/wyposazenie" element={<EquipmentCategories/>}/>
            <Route path="/produkty/wyposazenie/wlazy" element={<EquipmentManholesList/>}/>
            <Route path="/produkty/wyposazenie/wlazy/:id" element={<EquipmentManholeDetails/>}/>
            <Route path="/produkty/wyposazenie/drabiny" element={<EquipmentLaddersList/>}/>
            <Route path="/produkty/wyposazenie/drabiny/:id" element={<EquipmentLadderDetails/>}/>
            <Route path="*" element={<NoPage/>}/>
        </Routes>
    );
};