import React from "react";

interface SchemaImageListProps {
    images: string[];
}

const SchemaImageList = ({ images }: SchemaImageListProps) => {
    return (
        <div className="product-schema-images-container">
            {images.map((imageUrl, index) => (
                <img
                    key={index}
                    className={`schema-images-img${index + 1}`}
                    src={imageUrl}
                    alt={`Schema ${index + 1}`}
                ></img>
            ))}
        </div>
    );
};

export default SchemaImageList;